import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { appState, setShowModal } from "../stores/appSlice"
import i18n from "../i18n"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import {
  cmsState,
  popularCMSAsync,
  setShowPerchaseModal,
} from "../stores/cmsSlice"
import PurchaseModal from "./purchaseModal"

export const Footer = () => {
  const { t } = useTranslation()
  const { languages } = useAppSelector(appState)
  const { popularCMS } = useAppSelector(cmsState)
  const dispatchApp = useAppDispatch()
  const navigate = useNavigate()
  const { profile } = useAppSelector(appState)

  useEffect(() => {
    const found = languages?.data?.find(
      (element: any) => element.language_code == i18n.language
    )
    if (found?.language_id) {
      let params = {
        user_id: profile?.user_id ? profile?.user_id : "",
        lang_id: found?.language_id,
      }
      dispatchApp(popularCMSAsync(params))
    }
  }, [languages, i18n.language])

  const gotoCmsDetail = (
    isPurchased: boolean,
    cms_id: string,
    title: string,
    credit: any
  ) => {
    if (!profile?.user_id) {
      dispatchApp(setShowModal("signin"))
    } else if (isPurchased == false) {
      dispatchApp(
        setShowPerchaseModal({ cms_id: cms_id, title: title, credit: credit })
      )
    } else {
      navigate("/cms-detail/" + cms_id)
    }
  }

  return (
    <footer>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4">
              <div>
                <a href="#">
                  <img
                    height={"105px"}
                    src="/images/logo-gray.png"
                    alt="logo"
                  />
                </a>
              </div>
              <p className="mt-3 mb-4">{t("Follow us on social media!")}</p>
              <ul className="social-icon">
                <li>
                  <Link target="_blank" to="https://twitter.com/safetysurvivor">
                    <img
                      src="/images/icons/twitter.svg"
                      alt="Goodle"
                      className="img-fluid"
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.instagram.com/safetysurvivor/"
                  >
                    <img
                      src="/images/icons/instagram.svg"
                      alt="Goodle"
                      className="img-fluid"
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.facebook.com/profile.php?id=100093967353376"
                  >
                    <img
                      src="/images/icons/facebook.svg"
                      alt="Goodle"
                      className="img-fluid"
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://www.tiktok.com/@safetysurvivor"
                  >
                    <img
                      src="/images/tiktok.png"
                      alt="Goodle"
                      className="img-fluid"
                    />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-9 col-md-8">
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-sm-7">
                      <h6>{t("Popular Media")}</h6>
                      <ul>
                        {popularCMS?.data?.map((cms: any, index: number) => {
                          return (
                            <li key={`popcms${index}`}>
                              <a
                                href="#!"
                                onClick={() => {
                                  gotoCmsDetail(
                                    cms.isPurchased,
                                    cms.cms_id,
                                    cms.title,
                                    cms.credit
                                  )
                                }}
                              >
                                {cms.title}
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                    <div className="col-sm-5">
                      <h6>{t("How it Works?")}</h6>
                      <ul>
                        {/* <li><Link to="#">Colleges & Universities Leagues</Link></li>
                                                <li><Link to="#">Exposed Blog</Link></li>
                                                <li><Link to="#">Find a League</Link></li> */}
                        <li>
                          <Link to="/page/how-to-play" state={"how-to-play"}>
                            {t("How to Play")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/page/privacy-policy"
                            state={"privacy-policy"}
                          >
                            {t("Privacy Policy")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/page/faq" state={"faq"}>
                            {t("FAQs")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/page/terms-and-conditions"
                            state={"terms-and-conditions"}
                          >
                            {t("Terms and Conditions")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 martop2">
                  <div className="row">
                    <div className="col-sm-5">
                      <h6>{t("Store")}</h6>
                      <ul>
                        {/* <li><Link to="/coming-soon" state={"Products"} >{t("Products")}</Link></li> */}
                        <li>
                          <Link to="/orders" state={"My Orders"}>
                            {t("My Orders")}
                          </Link>
                        </li>
                        {/* <li><Link to="/coming-soon" state={"Find A Product"}>{t("Find a Product")}</Link></li> */}
                        <li>
                          <Link to="/my-account">{t("My Account")}</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-7">
                      <h6>{t("Contact Us")}</h6>
                      {/* <p>{t('You can reach us on')}</p> */}
                      <a
                        href="mailto:contact@safetysurvivor.com"
                        className="mt-4 text-black text-decoration-none"
                      >
                        <img
                          src="/images/email-orange.png"
                          alt="phone-orange"
                          className="img-fluid mr10"
                        />
                        {t("contact@safetysurvivor.com")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              © Copyrights 2023{" "}
              <a href="#" className="link-orange">
                safetysurvivor
              </a>{" "}
              - All Rights Reserved
            </div>
          </div>
        </div>
      </div>

      <PurchaseModal />
    </footer>
  )
}
