import { useTranslation } from "react-i18next";


const RemoteLanguage = (props: any) => {
    const { i18n } = useTranslation();
    if(!props.field){
       return <></>
    }
    return <>
        {props.field && props.field[i18n.language] ? props.field[i18n.language] : props.field['en']}
    </>
}

export default RemoteLanguage