import { useEffect } from 'react';
import './App.css';
import AppRoute from './routes';
import { BrowserRouter } from 'react-router-dom';
import i18n from './i18n';
import { ToastContainer, toast } from 'react-toastify';

function App() {
  useEffect(() => {
    const lang = localStorage.getItem("lang");
    if (lang) {
      i18n.changeLanguage(lang)
    } else {
      i18n.changeLanguage("en")
    }
  }, [])
  return (
    <BrowserRouter>
      <AppRoute />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </BrowserRouter>
  );
}

export default App;
