import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import { podcastState, getPodcastByIdAsync } from "../stores/podcastSlice"
import { useParams } from "react-router-dom"
import { t } from "i18next"
import { generateFileUrl } from "../utils/AzureStorageBlob"

export const PodcastDetail = () => {
  const dispatchApp = useAppDispatch()
  const params = useParams()
  const { status, podcastDetail, podcastAccessBuy } =
    useAppSelector(podcastState)

  useEffect(() => {
    dispatchApp(getPodcastByIdAsync(params.id))
  }, [params.id])

  return (
    <section className="contentpart">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-10">
            <div className="roundeddiv mt-4">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="position-relative contests-reg pb-2 mb-3 font50">
                    {/* {t("Title -")}  */}
                    {podcastDetail?.title}
                  </div>
                  <div className="ariclebyname mt-3">
                    {t("by")} {podcastDetail?.author}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <p>{podcastDetail?.description}</p>
            <div className="text-center mb-5 mt-5">
              <div className="box-shadow2 p-2 mb-5">
                <audio
                  controls
                  src={generateFileUrl(podcastDetail?.audio_link)}
                />
                {/* <source src={generateFileUrl(podcastDetail?.audio_link)} />
                </audio> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
