import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import {
  cmsState,
  getCmsByIdAsync,
  resetCmsAccessBuy,
} from "../stores/cmsSlice"
import { useParams } from "react-router-dom"
import { t } from "i18next"

export const CmsDetail = () => {
  const dispatchApp = useAppDispatch()
  const params = useParams()
  const { status, cmsDetail, cmsAccessBuy } = useAppSelector(cmsState)

  useEffect(() => {
    dispatchApp(getCmsByIdAsync(params.id))
  }, [params.id])

  return (
    <section className="contentpart">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-10">
            <div className="roundeddiv mt-4">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="position-relative contests-reg pb-2 mb-3 font50">
                    {/* {t("Title -")}  */}
                    {cmsDetail?.title}
                  </div>
                  <div className="ariclebyname mt-3">
                    {t("by")} {cmsDetail?.author_name}
                  </div>
                </div>
                <div className="col-lg-7">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="mb-5 mt-5">
              <div
                className="font15"
                dangerouslySetInnerHTML={{ __html: cmsDetail?.content }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
