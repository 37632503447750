import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import Config from './config';
import store from '../stores';
import { setAppLoader } from '../stores/appSlice';

const sasToken = Config.sasToken
const storageAccountName = Config.storageAccountName;

export const isStorageConfigured = () => {
  return (!storageAccountName || !sasToken) ? false : true;
}

const createBlobInContainer = async (containerClient: any, file: File, dir: string) => {
  const fn = file.name.replace(/[^a-zA-Z0-9\.]/g, "")
  const fileName = (new Date()).getTime() + "_" + Math.random() + "_" + fn;
  let fileLocation = "";

  if (dir) {
    fileLocation = `${dir}/`
  }
  const blobClient = containerClient.getBlockBlobClient(`${fileLocation}` + fileName);
  const options = { blobHTTPHeaders: { blobContentType: file.type } };
  const res = await blobClient.uploadData(file, options);
  if (res?._response?.status === 201) {
    return fileName;
  }
  return ""
}

const uploadFileToBlob = async (file: File, container: string, dir: string = "") => {
  if (!file) return "";

  store.dispatch(setAppLoader(true))
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  const containerClient = blobService.getContainerClient(container);
  await containerClient.createIfNotExists({
    access: 'container',
  });
  const fileName = await createBlobInContainer(containerClient, file, dir);
  store.dispatch(setAppLoader(false))
  if (fileName) {
    return `${fileName}`;
  }
  return ""
};


export const generateFileUrl = (fileName: string) => {
  return `${Config.CDN_URL}${fileName}`
}
export default uploadFileToBlob;