import { useEffect, useState } from "react"
import PaymentForm from "../pages/payment"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import { contestState, getContestAsync } from "../stores/contestSlice"
import { generateFileUrl } from "../utils/AzureStorageBlob"
import RemoteLanguage from "./remoteLang"
import { useTranslation } from "react-i18next"
import moment from "moment"
import Rating from "./rating"
import { Console } from "console"
import { Link } from "react-router-dom"
import {
  localNumber,
  numberFormat,
  preventNegativeValues,
  showErrorToast,
  showSuccessToast,
} from "../utils/commonFun"
import { Modal } from "react-bootstrap"
import {
  createPaymentSession,
  paymentCancle,
  paymentSuccess,
} from "../api/appApi"

import { useLocation } from "react-router-dom"

const BuyMediaCredit = () => {
  const { contest, status } = useAppSelector(contestState)
  const [tab, setTab] = useState<number>(1)
  const [termsConditions, setTermsConditions] = useState<boolean>(false)
  const [mediaCredit, setMediaCredit] = useState<number>(100)
  const [total, setTotal] = useState<number>(0)
  const [limit, setLimit] = useState(3)
  const [page, setPage] = useState(1)
  const dispatchApp = useAppDispatch()
  const { t } = useTranslation()
  const [contestMediaCredit, setContestMediaCredit] = useState<any>([])
  const [contestInputError, setContestInputError] = useState<boolean>(false)
  const [maximunPrize, setMaximumPrize] = useState<number>(0)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [showWarningModel, setShowWarningModel] = useState(false)

  const location = useLocation()

  useEffect(() => {
    let params = {
      limit: limit,
      page: page,
      status: "Open",
    }
    dispatchApp(getContestAsync(params))
  }, [limit, page])

  const createPaymentSessionFun = async () => {
    let _contestMediaCredit = []

    for (let i = 0; i < contestMediaCredit.length; i++) {
      if ((contestMediaCredit[i] != undefined || contestMediaCredit[i] != null) && contestMediaCredit[i].credit !== 0 ) {
        _contestMediaCredit.push(contestMediaCredit[i])
      }
    }

    const data = {
      credit: mediaCredit,
      contests: _contestMediaCredit,
    }
    const createPaySession = await createPaymentSession(data)
    if (
      createPaySession.data.statusCode == 200 ||
      createPaySession.data.statusCode == 201
    ) {
      localStorage.setItem("cs_id", createPaySession.data.data.cs_id)
      window.open(createPaySession.data.data.payment_link, "_self")
    } else {
      showErrorToast(createPaySession.data.message)
    }
  }

  // const goToTabTwo = () => {
  //   setIsSubmitted(true)
  //   if (mediaCredit <= 1) {
  //     showErrorToast(t("Please purchase a minimum of 2 Midea Credits."))
  //   } else if (total > mediaCredit) {
  //     setContestInputError(true)
  //     showErrorToast(
  //       t("Total credit assigned to contests is more then x.", {
  //         mediaCredit: mediaCredit,
  //       })
  //     )
  //   } else if (!termsConditions && contest.length !== 0) {
  //     return
  //   } else if (total < mediaCredit) {
  //     if (contest.length == 0) {
  //       setTab(2)
  //     } else {
  //       setShowWarningModel(true)
  //     }
  //   } else {
  //     setTab(2)
  //   }
  // }

  const handleOnChange = (e: any, index: number, price_pool: number) => {
    const { name, value } = e.target
    let data = [...contestMediaCredit]
    data[index] = {
      contest_id: name,
      credit: Number(value),
      price_pool: price_pool,
    }

    let _total = 0
    for (let i = 0; i < data?.length; i++) {
      _total += data[i] ? Number(data[i]?.credit) : 0
    }

    let MaxPrice = 0
    for (let i = 0; i < data?.length; i++) {
      MaxPrice += data[i]
        ? Number(data[i]?.credit) * Number(data[i]?.price_pool)
        : 0
    }

    setTotal(_total)
    setContestMediaCredit([...data])
    setMaximumPrize(MaxPrice)
  }

  useEffect(() => {
    if (mediaCredit > total && total != 0) {
      setContestInputError(true)
    } else {
      setContestInputError(false)
    }
  }, [contestMediaCredit, total, mediaCredit])

  const makePayment = () => {
    setIsSubmitted(true)
    if (!termsConditions && contest.length !== 0) {
      return
    } else if (mediaCredit <= 2) {
      showErrorToast(t("Please purchase a minimum of 2 Midea Credits."))
    } else if (total > mediaCredit) {
      setContestInputError(true)
      showErrorToast(
        t("Total credit assigned to contests is more then x.", {
          mediaCredit: mediaCredit,
        })
      )
    } else if (total < mediaCredit) {
      if (contest.length !== 0) {
        setShowWarningModel(true)
      } else {
        createPaymentSessionFun()
      }
    } else {
      createPaymentSessionFun()
    }
  }

  return (
    <div className="contentpart">
      <div className="contest-box position-relative">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="stepsulli my-4">
                <div className="row align-items-center">
                  <div className="col-4">
                    <a
                      href="#"
                      onClick={() => {
                        setTab(1)
                      }}
                      className={tab === 1 ? "active" : ""}
                    >
                      <span className="number">01</span>
                      <span className="d-block">{t("buy_media_credits")}</span>
                    </a>
                  </div>
                  <div className="col-4">
                    <img
                      src="/images/icons/steps-line.svg"
                      alt="steps-line"
                      className="stepline"
                    />
                  </div>
                  <div className="col-4">
                    <a href="#" className={tab === 2 ? "active" : ""}>
                      <span className="number">02</span>
                      <span className="d-block">{t("payment-details")}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {tab == 1 ? (
            <div>
              <div className="order-summary my-3">
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <div className="white-box add-credits p-4 my-2">
                      <form>
                        <div className="relative my-2">
                          <label className="form-label">
                            {t("add-credits")}
                          </label>
                          <input
                            id="media_credit_imput"
                            type="number"
                            className={
                              mediaCredit < 2
                                ? "form-control error"
                                : "form-control"
                            }
                            value={mediaCredit}
                            onWheel={(event) => event.currentTarget.blur()}
                            onChange={(e: any) => {
                              setMediaCredit(e?.target?.value)
                            }}
                            placeholder="150"
                          />
                          <img
                            src="/images/icons/green-check.svg"
                            alt="email"
                            className="ss-form-email"
                          />
                        </div>
                        <div className="mt-4 mb-3">
                          <button
                            type="button"
                            onClick={() => setMediaCredit(100)}
                            className="btn btn-border me-1"
                          >
                            100
                          </button>
                          <button
                            type="button"
                            onClick={() => setMediaCredit(250)}
                            className="btn btn-border me-1"
                          >
                            250
                          </button>
                          <button
                            type="button"
                            onClick={() => setMediaCredit(500)}
                            className="btn btn-border me-1"
                          >
                            500
                          </button>
                          <button
                            type="button"
                            onClick={() => setMediaCredit(1000)}
                            className="btn btn-border me-1"
                          >
                            1000
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="white-box position-relative my-2">
                      <div className="row align-items-center">
                        <div className="col-lg-5">
                          <div className="league-match h-100 text-center">
                            <img
                              src="/images/icons/cart.svg"
                              alt="cart"
                              className="me-2"
                            />
                            {t("order-summary")}
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <div className="position-relative bysuper-short h-100">
                            <div className="row align-items-center">
                              <div className="col-sm-8">
                                <div className="font17">
                                  {localNumber(mediaCredit)}{" "}
                                  {t("media-credits")} x $1.5
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="font26 text-end">
                                  {Number(mediaCredit * 1.5).toLocaleString(
                                    "en-CA",
                                    { style: "currency", currency: "CAD" }
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="dassed-line"></div>
                            <div className="row align-items-center">
                              <div className="col-sm-8">
                                <div className="font17 font-w500">
                                  {t("total")}
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="font26 font-w600 text-end">
                                  {Number(mediaCredit * 1.5).toLocaleString(
                                    "en-CA",
                                    { style: "currency", currency: "CAD" }
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row align-items-center mt-4">
                              <div className="col-sm-8">
                                <div className="font13">
                                  {localNumber(mediaCredit)}{" "}
                                  {t("contest-credits")}
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="font20 text-end">
                                  {t("free")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {contest.length > 0 ? (
                <>
                  <div className="mt-5 mb-4">
                    <div className="row">
                      <div className="col-12">
                        <div className="font15 text-center">
                          {t(
                            "You will receive an equal amount of contest credits that you can allocate to the contests of your choosing below"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="white-box contest-credits">
                        <div className="p-4">
                          <div className="text-center font17">
                            {t("contest-credits-to-allocate")}{" "}
                            <img
                              src="/images/icons/tiket.svg"
                              alt="tiket"
                              className="ms-3 me-2"
                            />
                            <span className="font-w500">
                              {localNumber(mediaCredit - total)}
                            </span>
                          </div>
                        </div>
                        <div className="border-top pt-4 pb-4 pe-3 ps-3">
                          <div className="text-center mb-3">
                            {t(
                              "please-allocate-all-available-contest-credits-below"
                            )}
                          </div>
                          <div className="contests-sec text-center">
                            <div className="row">
                              {contest.length == 0 && (
                                <>
                                  <div className=" font-w300 font22 pt-4 m-4">
                                    {t("No Contests found")}
                                  </div>
                                </>
                              )}

                              {contest?.map((item: any, index: number) => {
                                return (
                                  <div
                                    key={`contest_ ${index}`}
                                    className="col-lg-4 col-md-12"
                                  >
                                    <div className="sports-list">
                                      <div className="home-sports-box-bg">
                                        <div className="sportimg">
                                          <img
                                            src={generateFileUrl(item?.banner)}
                                            alt="Football"
                                          />
                                        </div>
                                        <h2>
                                          <RemoteLanguage field={item?.title} />
                                          {item?.rules_file && (
                                            <Link
                                              to={generateFileUrl(
                                                item?.rules_file
                                              )}
                                              target="_blank"
                                            >
                                              <img
                                                src="/images/icons/notepad.svg"
                                                alt="notepad"
                                                className="ms-1"
                                              />
                                            </Link>
                                          )}
                                        </h2>
                                        <span className="prize-per-winning-entry">
                                          {t("prize_per_winning_entry")}{" "}
                                          <span>
                                            {numberFormat(item?.price_pool)}
                                          </span>
                                        </span>
                                        <div className="font-w500 text-black">
                                          <RemoteLanguage
                                            field={item?.Sport?.sport_name}
                                          />
                                        </div>
                                        <div className="font12">
                                          {t("registration_closes")} -{" "}
                                          {moment(
                                            item?.reg_close_date_time
                                          ).format("MM/DD/YYYY HH:mm")}
                                        </div>
                                      </div>
                                      <Rating complexity={item.complexity} />
                                      <div className="font20 font-w500 mt-4">
                                        <img
                                          src="/images/icons/tiket.svg"
                                          alt="tiket"
                                          className="me-1"
                                        />
                                        <span className="tiketcheck">
                                          <span>
                                            <input
                                              className={
                                                mediaCredit < total
                                                  ? "contest-input error"
                                                  : "contest-input"
                                              }
                                              onWheel={(event) =>
                                                event.currentTarget.blur()
                                              }
                                              defaultValue={
                                                contestMediaCredit[index]
                                                  ?.credit == undefined
                                                  ? 0
                                                  : contestMediaCredit[index]
                                                      ?.credit
                                              }
                                              type="number"
                                              min={0}
                                              step={1}
                                              value={
                                                contestMediaCredit[index]
                                                  ?.credit == undefined
                                                  ? 0
                                                  : Number(
                                                      contestMediaCredit[index]
                                                        ?.credit
                                                    ).toString()
                                              }
                                              name={item.contest_id}
                                              onChange={(e) => {
                                                handleOnChange(
                                                  e,
                                                  index,
                                                  item?.price_pool
                                                )
                                              }}
                                              onKeyDown={preventNegativeValues}
                                              onPaste={(e: any) => {
                                                e.preventDefault()
                                                return false
                                              }}
                                            />
                                          </span>
                                          {contestMediaCredit[index]?.credit &&
                                          Number(
                                            contestMediaCredit[index]?.credit
                                          ) >= 1 ? (
                                            <span className="float-end">
                                              <img
                                                src="/images/icons/green-check.svg"
                                                alt="green-check"
                                              />
                                            </span>
                                          ) : null}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>

                            {(contest.length == page * limit ||
                              status == "loading") && (
                              <div className="mt-3 mb-5">
                                <a
                                  onClick={() => {
                                    setPage(page + 1)
                                  }}
                                  className="btn btn-orange box-shadow view-more-btn"
                                >
                                  {status == "loading"
                                    ? t("loading")
                                    : t("view-more")}{" "}
                                </a>
                              </div>
                            )}

                            <div className="mb-4">
                              <img
                                src="/images/icons/price-win-symbol.svg"
                                className="img-fluid m-auto"
                              />
                            </div>
                            <div className="text-center mb-4">
                              <span className="max-prize">
                                {t("maximum-prize")} :
                                <strong>{numberFormat(maximunPrize)}</strong>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              <div className="mt-5 mb-4 text-center">
                <div className="row">
                  <div className="col-md-12">
                    <div className="checkbox-mark font13">
                      {contest.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <input
                            id="rules-check"
                            checked={termsConditions}
                            onChange={() =>
                              setTermsConditions(!termsConditions)
                            }
                            type="checkbox"
                            className={
                              isSubmitted && !termsConditions
                                ? "form-check-input error"
                                : "form-check-input"
                            }
                          />
                          <label
                            htmlFor="rules-check"
                            className="form-check-label"
                          >
                            {t("confirm-rules")}
                          </label>
                        </>
                      )}
                    </div>
                    <div className="mt-4">
                      <div
                        onClick={() => {
                          makePayment()
                        }}
                        className="btn btn-orange"
                      >
                        {t("make-payment")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="my-5">
              <div className="row">
                <div className="col-lg-7">
                  <PaymentForm
                    setTab={() => setTab(1)}
                    mediaCredit={mediaCredit}
                    amount={mediaCredit * 1500}
                    contests={contestMediaCredit}
                  />
                </div>

                <div className="col-lg-5">
                  <div className="white-box cartbox">
                    <div className="p-4 text-center text-orange font26 font-w500">
                      {t("cart")}
                    </div>
                    <div className="cart-summry">
                      <img
                        src="/images/icons/cart.svg"
                        alt="cart"
                        className="me-2"
                      />
                      {t("order-summary")}
                    </div>
                    <div className="pe-4 ps-4 pt-4">
                      <div className="row align-items-center">
                        <div className="col-sm-8">
                          <div className="font18 font-w500">
                            {t("item-name")}
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="font18 font-w500 text-end">
                            {t("price")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dassed-line"></div>
                    <div className="pe-4 ps-4 py-3">
                      <div className="row align-items-center">
                        <div className="col-sm-8">
                          <div className="font18">
                            {mediaCredit} {t("media_credits")}
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="font26 text-end">
                            {Number(mediaCredit * 1.5).toLocaleString("en-CA", {
                              style: "currency",
                              currency: "CAD",
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dassed-line"></div>
                    <div className="pe-4 ps-4 pt-2 pb-4">
                      <div className="row align-items-center">
                        <div className="col-sm-8">
                          <div className="font18 font-w500">{t("total")}</div>
                        </div>
                        <div className="col-sm-4">
                          <div className="font26 font-w600 text-end">
                            {Number(mediaCredit * 1.5).toLocaleString("en-CA", {
                              style: "currency",
                              currency: "CAD",
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal show={showWarningModel} onHide={() => setShowWarningModel(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Warning")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("less_usge_model")}</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-orange box-shadow"
            onClick={() => {
              setShowWarningModel(false)
            }}
          >
            {" "}
            {t("Back")}{" "}
          </button>
          <button
            onClick={() => {
              createPaymentSessionFun()
              setShowWarningModel(false)
            }}
            className="btn btn-orange box-shadow"
          >
            {" "}
            {t("PROCEED")}{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default BuyMediaCredit
