import { Link, useNavigate, useParams } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import {
  contestState,
  getContestByIdAsync,
  setContestRoundById,
} from "../stores/contestSlice"
import { generateFileUrl } from "../utils/AzureStorageBlob"
import moment from "moment"
import { t } from "i18next"
import Rating from "./rating"
import RemoteLanguage from "./remoteLang"
import {
  localNumber,
  numberFormat,
  preventNegativeValues,
  showErrorToast,
  showSuccessToast,
} from "../utils/commonFun"
import {
  addParticipant,
  getContestById,
  getContestRoundGamesById,
} from "../api/appApi"
import { Modal } from "react-bootstrap"

const ContestDetail = () => {
  const params = useParams()
  const navigation = useNavigate()
  const [credit, setCredit] = useState<any>([])
  const [contestById, setContestById] = useState<any>({})
  const [contestRoundById, setContestRoundById] = useState<any>({})

  const [totalCredit, setTotalCredit] = useState<number>(0)
  const [currentRoundData, setCurrentRoundData] = useState<any>({})
  const [creditWon, setCreditWon] = useState<number>(0)
  const [isModelOpen, setIsModelOpen] = useState(false)
  const [showCloseModel, setShowCloseModel] = useState(false)
  const [showWonModel, setShowWonModel] = useState(false)

  const [isEdited, setIsEdited] = useState(false)

  const getConteste = async () => {
    const response = await getContestById(params.id)
    if (response && response.statusText === "OK") {
      setContestById(response.data.data)

      let round: any = {}
      if (response.data.data.status === "Close") {
        round =
          response.data.data.ContestRounds[
            response.data.data.ContestRounds.length - 1
          ]
      } else if (response.data.data.ContestRounds.length >= 1) {
        round = response.data.data.ContestRounds[0]
        for (let i = 0; i < response.data.data.ContestRounds.length; i++) {
          if (response.data.data.ContestRounds[i].status === "In progress") {
            round = response.data.data.ContestRounds[i]
          } else if (
            round.status !== "In progress" &&
            response.data.data.ContestRounds[i].status === "Close"
          ) {
            round = response.data.data.ContestRounds[i]
          }
        }
      }
      setContestRoundById(round)
    }
  }
  useEffect(() => {
    getConteste()
  }, [params.id])

  useEffect(() => {
    setShowWonModel(false)
    if (contestById.status === "Close" && creditWon > 0) {
      setShowWonModel(true)
    }
  }, [contestById.status, creditWon])

  useEffect(() => {
    let _creditWon = 0
    for (let i = 0; i < currentRoundData?.ContestRoundGames?.length; i++) {
      for (
        let j = 0;
        j < currentRoundData?.ContestRoundGames[i].ContestRoundGameTeams.length;
        j++
      ) {
        if (
          (currentRoundData?.ContestRoundGames[i].ContestRoundGameTeams[j]
            .is_winner ||
            currentRoundData?.ContestRoundGames[i]?.credit_forward) &&
          currentRoundData?.ContestRoundGames[i].ContestRoundGameTeams[j]
            .ContestRoundParticipant
        ) {
          _creditWon += Number(
            currentRoundData?.ContestRoundGames[i].ContestRoundGameTeams[j]
              .ContestRoundParticipant.credit
          )
        }
      }
    }
    setCreditWon(_creditWon)
  }, [currentRoundData])

  const getGamesByContectRoundId = async () => {
    const res = await getContestRoundGamesById(
      contestRoundById.contest_round_id
    )
    if (res.status === 200) {
      setCurrentRoundData(res.data.data)
      let _credit: any = {}
      let _creditWon = 0
      for (let i = 0; i < res.data.data.ContestRoundGames.length; i++) {
        const round = res.data.data.ContestRoundGames[i]
        for (let j = 0; j < round.ContestRoundGameTeams.length; j++) {
          if (round.ContestRoundGameTeams[j].ContestRoundParticipant) {
            _credit[round.ContestRoundGameTeams[j].contest_round_game_team_id] =
              {
                contest_round_game_team_id:
                  round.ContestRoundGameTeams[j].contest_round_game_team_id,
                credit:
                  round.ContestRoundGameTeams[j].ContestRoundParticipant.credit,
              }
          }
          if (
            (round.ContestRoundGameTeams[j].is_winner ||
              round.credit_forward) &&
            round.ContestRoundGameTeams[j].ContestRoundParticipant
          ) {
            _creditWon += Number(
              round.ContestRoundGameTeams[j].ContestRoundParticipant.credit
            )
          }
        }
      }
      if (res.data.data.status == "Close" && _creditWon === 0) {
        setShowCloseModel(true)
      } else if (
        res.data.data.status == "In progress" &&
        contestRoundById.round_credit === 0
      ) {
        setShowCloseModel(true)
      } else {
        setShowCloseModel(false)
      }
      setCredit(_credit)
    } else {
      navigation("/")
    }
  }

  useEffect(() => {
    if (contestRoundById.contest_round_id) {
      getGamesByContectRoundId()
    }
  }, [contestRoundById.contest_round_id])

  const onParticipate = async () => {
    if (totalCredit <= contestRoundById?.round_credit) {
      var participant: any[] = []
      Object.keys(credit).map((key, i) => {
        if (Number(credit[key].credit) > 0) {
          participant.push({
            contest_round_game_team_id: credit[key].contest_round_game_team_id,
            credit: Number(credit[key].credit),
          })
        }
      })
      const data = {
        contest_id: params.id,
        participate: participant,
        contest_round_id: contestRoundById.contest_round_id,
      }
      const res = await addParticipant(data)
      if (res.status === 201) {
        showSuccessToast(res.data.message)
      } else if (res.status === 200) {
        showSuccessToast(res.data.message)
      } else {
        showErrorToast(res.data.message)
      }
    } else {
    }
  }

  useEffect(() => {
    var _totalCredit = 0
    Object.keys(credit).map((key, i) => {
      if (
        credit[key].contest_round_game_team_id &&
        Number(credit[key].credit) > 0
      ) {
        _totalCredit += Number(credit[key].credit)
      }
    })
    setTotalCredit(_totalCredit)
  }, [credit])

  const getRoundResult = () => {
    return (
      <div className="ronded-listing">
        <div className="row justify-content-center mb-4">
          <div className="col-md-6">
            <div className="font20 font-w500 entries-calost">
              {contestById?.ContestRounds[contestById.ContestRounds.length - 1]
                ?.contest_round_id == contestRoundById.contest_round_id ? (
                <>
                  {creditWon == 0 ? (
                    <div className="text-lost">
                      {t("Unfortunately, all of your entries have been lost")}
                    </div>
                  ) : contestRoundById?.round_credit == creditWon ? (
                    <div className="text-win">
                      {t("all_won_cong")}
                      <br />
                      {t("all_won_cong_line2", {
                        entries: creditWon,
                        amount: localNumber(
                          contestById?.price_pool * creditWon
                        ),
                      })}
                    </div>
                  ) : (
                    <>
                      <div className="text-win">
                        {t("all_won_cong")}
                        <br />
                        {t("all_won_cong_line2", {
                          entries: creditWon,
                          amount: localNumber(
                            contestById?.price_pool * creditWon
                          ),
                        })}
                      </div>
                      <div className="text-lost">
                        {t("The rest of your entries have lost.")}
                      </div>
                    </>
                  )}
                </>
              ) : creditWon == 0 ? (
                <div className="text-lost">
                  {t("Unfortunately, all of your entries have been lost")}
                </div>
              ) : contestRoundById?.round_credit == creditWon ? (
                <div className="text-win">
                  {t("All of your entries are carried forward!")}
                </div>
              ) : (
                <>
                  <div className="text-win">
                    {t("Your x entries are carried forward.", {
                      creditWon: creditWon,
                    })}
                  </div>
                  <div className="text-lost">
                    {t("You have lost y entries", {
                      lost: localNumber(
                        contestRoundById?.round_credit - creditWon
                      ),
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {currentRoundData?.ContestRoundGames?.map(
          (game: any, index: number) => {
            var a = moment()
            var b = moment(game.open_till)
            const timeDiff = b.diff(a)

            return (
              <div key={game.game_id} className="ronded-gridview-main mb-5">
                <div className="openallocation font20">
                  {timeDiff < 0 ? t("Completed") : ""}
                </div>
                {/* <div className="openallocation font20">
                                    {timeDiff < 0 ? t("Allocation Closed AT") : t("Open For Allocation Till")} <span className="text-black font-w500">{moment(game.open_till).format("hh:mm A, MM/DD/Y")}</span>
                                </div>
                                */}
                {contestById.is_team == false && (
                  <div className="stadium-name mb-2">
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div className="font24 text-black font-w500">
                          {game.place_name}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <ul className="dateandtime-grid">
                          <li>
                            <div className="font22">
                              <img
                                src="/images/icons/calander.svg"
                                alt="tiket"
                                className="me-2"
                              />
                              {moment(game.game_time).format("MM/DD/Y")}
                            </div>
                          </li>
                          <li>
                            <div className="font22">
                              <img
                                src="/images/icons/time.svg"
                                alt="tiket"
                                className="me-2"
                              />
                              {moment(game.game_time).format("hh:mm A")}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row align-items-center">
                  {game.ContestRoundGameTeams?.map(
                    (team: any, index: number) => {
                      return (
                        <React.Fragment key={`nasme${index}`}>
                          <div
                            className={
                              contestById.is_team == 1 ? "col-md-4" : "col-md-3"
                            }
                          >
                            <div
                              className={
                                contestById.is_team == 1
                                  ? "marbot30"
                                  : "ronded-gridview"
                              }
                            >
                              <div className="mb-3">
                                <img
                                  src={generateFileUrl(team.Team?.logo)}
                                  alt="bestfootball"
                                  className={
                                    contestById.is_team == 1
                                      ? "img-fluid team-image"
                                      : "img-fluid img100 radius100"
                                  }
                                />
                              </div>
                              <div className="my-2 font17 text-black">
                                <RemoteLanguage field={team.Team?.name} />
                              </div>
                              {/* {team?.is_winner ?
                                                        <div className="my-2 font24 font-w600 text-win">{t("WIN")}</div>
                                                        : <div className="my-2 font24 font-w600 text-lost">{t("LOST")}</div>
                                                    } */}
                              {/* {team?.ContestRoundParticipant ?
                                                        <> */}
                              <div className="font20 font-w500 winicon d-flex justify-content-center align-items-center">
                                <img
                                  height={"25px"}
                                  className="pe-2"
                                  src={
                                    team.is_winner || game?.credit_forward
                                      ? "/images/icons/green-check.svg"
                                      : "/images/icons/x-circle-red.svg"
                                  }
                                  alt="win"
                                />
                                <span className="font32">
                                  {team?.ContestRoundParticipant?.credit
                                    ? team?.ContestRoundParticipant?.credit
                                    : 0}
                                </span>
                              </div>
                              {/* </>
                                                        : null} */}
                            </div>
                          </div>
                          {index === 0 && contestById.is_team == 1 ? (
                            <div className="col-md-4">
                              <div className="stadium-namediv marbot30">
                                {/* <div className="mb-3"> <img src="/images/vs.png" /> </div> */}
                                <div className="font22">
                                  <img
                                    src="/images/icons/calander.svg"
                                    alt="tiket"
                                    className="me-2"
                                  />
                                  {moment(game?.game_time).format("MM/DD/Y")}
                                </div>
                                <div className="font22 mt-2">
                                  <img
                                    src="/images/icons/time.svg"
                                    alt="tiket"
                                    className="me-2"
                                  />
                                  {moment(game?.game_time).format("hh:mm A")}
                                </div>
                                <div className="font24 text-black font-w500 mt-3">
                                  {game?.place_name}
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </React.Fragment>
                      )
                    }
                  )}
                </div>
              </div>
            )
          }
        )}
      </div>
    )
  }

  if (contestById.contest_id !== params.id) {
    return (
      <section className="contentpart">
        <div className="contest-box position-relative">
          <div className="boxmidline"></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="mt-5 mb-5">
                  <div className="white-box position-relative">
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="league-match h-100">
                          <div className="row align-items-center">
                            <div className="col-lg-5"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  return (
    <section className="contentpart">
      <div className="contest-box position-relative">
        <div className="boxmidline"></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="mt-5 mb-5">
                <div className="white-box position-relative">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6">
                      <div className="league-match h-100">
                        <div className="row align-items-center">
                          <div className="col-lg-5">
                            <img
                              src={generateFileUrl(contestById?.banner)}
                              alt="Football"
                              className="img-fluid"
                            />
                          </div>
                          <div className="col-lg-7">
                            <h2>
                              <RemoteLanguage field={contestById?.title} />
                              {contestById?.rules_file && (
                                <Link
                                  to={generateFileUrl(contestById?.rules_file)}
                                  target="_blank"
                                >
                                  <img
                                    src="/images/icons/notepad.svg"
                                    alt="notepad"
                                    className="ms-1"
                                  />
                                </Link>
                              )}
                            </h2>
                            <span className="prize-per-winning-entry">
                              {t("prize_per_winning_entry")}{" "}
                              <span>
                                {numberFormat(contestById?.price_pool)}
                              </span>
                            </span>
                            <div className="font-w500 text-black">
                              <RemoteLanguage
                                field={contestById?.Sport?.sport_name}
                              />
                            </div>
                            <div className="font12">
                              {t("registration_closes")} -{" "}
                              {moment(contestById?.reg_close_date_time).format(
                                "MM/DD/YYYY HH:mm"
                              )}{" "}
                            </div>
                            <Rating complexity={contestById?.complexity} />
                          </div>
                        </div>
                      </div>
                    </div>

                    {contestById?.Sponsorship ? (
                      <div className="col-lg-6">
                        <div className="position-relative bysuper-short h-100">
                          <div className="font22 font-w300 mb-3">
                            {t("by")}
                            <img
                              src={generateFileUrl(
                                contestById?.Sponsorship?.logo
                              )}
                              alt="ss football clube logo"
                              className="ms-2 img-mx-height img-fluid"
                            />
                          </div>
                          <div className="font13">
                            {contestById?.Sponsorship?.company_offering}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-sm-11">
            <div className="roundeddiv">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <div className="position-relative roundedname font50">
                    <RemoteLanguage field={currentRoundData?.title} />
                  </div>
                </div>
                <div className="col-lg-6">
                  {contestRoundById?.Sponsorship ? (
                    <div className="roundedmidbox">
                      <div className="row align-items-center">
                        <div className="col-sm-5">
                          <div className="font22 font-w300 my-1">
                            {t("by")}
                            <img
                              src={generateFileUrl(
                                currentRoundData?.Sponsorship?.logo
                              )}
                              alt="ss football clube logo"
                              className="ms-2 img-fluid"
                            />
                          </div>
                        </div>
                        <div className="col-sm-7">
                          <div className="my-1">
                            {currentRoundData?.Sponsorship?.company_offering}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-lg-3">
                  <div className="starting-entry">
                    <div className="font-w500">
                      {t("starting-entries")}
                      <img
                        src="/images/icons/tiket.svg"
                        alt="tiket"
                        className="me-2"
                      />
                      <span className="font20">
                        {localNumber(
                          contestRoundById?.round_credit - totalCredit
                        )}
                        <span className="font12">
                          /{localNumber(contestRoundById?.round_credit)}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div className="row">
            <div className="col-md-1">
              <div className="position-relative text-center">
                {currentRoundData?.ContestRoundGames?.length > 0 &&
                  contestById?.ContestRounds?.map(
                    (round: any, index: number) => {
                      return (
                        <div
                          key={round.round_id}
                          onClick={() => {
                            if (
                              round?.status !== "Open" &&
                              round?.status !== "Draft"
                            ) {
                              setContestRoundById(round)
                            }
                          }}
                          className={
                            round?.status == "In progress"
                              ? "rounded-number round-active"
                              : round?.status == "Open" ||
                                round?.status == "Draft"
                              ? "rounded-number round-next"
                              : "rounded-number"
                          }
                        >
                          <div className="roundcircle">{index + 1}</div>
                          <RemoteLanguage field={round?.title} />
                          {index < contestById?.ContestRounds?.length - 1 ? (
                            <div className="vline"></div>
                          ) : null}
                        </div>
                      )
                    }
                  )}
              </div>
            </div>

            <div className="col-md-11">
              {currentRoundData?.ContestRoundGames?.length > 0 &&
              currentRoundData.status === "Close" ? (
                getRoundResult()
              ) : currentRoundData?.ContestRoundGames?.length > 0 &&
                contestById.is_team ? (
                <>
                  {currentRoundData?.ContestRoundGames?.map(
                    (game: any, index: number) => {
                      var a = moment()
                      var b = moment(game.open_till)
                      const timeDiff = b.diff(a)

                      return (
                        <div
                          className="ronded-listing"
                          key={`roundata${index}`}
                        >
                          <div className="openallocation font20">
                            {" "}
                            {timeDiff < 0
                              ? t("Allocation Closed AT")
                              : t("Open For Allocation Till")}{" "}
                            <span className="text-black font-w500">
                              {moment(game.open_till).format(
                                "hh:mm A, MM/DD/Y"
                              )}
                            </span>
                          </div>
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <div className="marbot30">
                                <div className="mb-3">
                                  <img
                                    src={generateFileUrl(
                                      game?.ContestRoundGameTeams[0]?.Team?.logo
                                    )}
                                    alt="bestfootball"
                                    className="img-fluid team-image"
                                  />
                                </div>
                                <div className="my-2 font17 text-black">
                                  <RemoteLanguage
                                    field={
                                      game?.ContestRoundGameTeams[0]?.Team?.name
                                    }
                                  />
                                </div>
                                {game.status !== "Pending" ? (
                                  <>
                                    <div className="font20 font-w500 winicon d-flex justify-content-center align-items-center">
                                      <img
                                        height={"25px"}
                                        className="pe-2"
                                        src={
                                          game?.ContestRoundGameTeams[0]
                                            ?.is_winner
                                            ? "/images/icons/green-check.svg"
                                            : "/images/icons/x-circle-red.svg"
                                        }
                                        alt="win"
                                      />
                                      <span className="font32">
                                        {game?.ContestRoundGameTeams[0]
                                          ?.ContestRoundParticipant?.credit
                                          ? game?.ContestRoundGameTeams[1]
                                              ?.ContestRoundParticipant?.credit
                                          : 0}
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <div className="font20 font-w500">
                                    <img
                                      src="/images/icons/tiket.svg"
                                      alt="tiket"
                                      className="me-1"
                                    />
                                    {/* 25<img src="/images/icons/edit.svg" alt="tiket" className="ms-2" /> */}

                                    <span className="tiketcheck">
                                      <input
                                        type="number"
                                        min={0}
                                        onWheel={(event) =>
                                          event.currentTarget.blur()
                                        }
                                        disabled={timeDiff < 0}
                                        defaultValue={0}
                                        onKeyDown={preventNegativeValues}
                                        onPaste={(e: any) => {
                                          e.preventDefault()
                                          return false
                                        }}
                                        value={
                                          credit[
                                            game?.ContestRoundGameTeams[0]
                                              ?.contest_round_game_team_id
                                          ]?.credit
                                            ? credit[
                                                game?.ContestRoundGameTeams[0]
                                                  ?.contest_round_game_team_id
                                              ]?.credit
                                            : game?.ContestRoundGameTeams[0]
                                                ?.ContestRoundParticipant
                                                ?.credit
                                        }
                                        className={
                                          totalCredit >
                                          contestRoundById?.round_credit
                                            ? "contest-input error"
                                            : "contest-input"
                                        }
                                        onChange={(e: any) => {
                                          let _credit = { ...credit }
                                          _credit[
                                            game?.ContestRoundGameTeams[0]?.contest_round_game_team_id
                                          ] = {
                                            contest_round_game_team_id:
                                              game?.ContestRoundGameTeams[0]
                                                ?.contest_round_game_team_id,
                                            credit: Number(
                                              e.target.value
                                            ).toString(),
                                          }
                                          setIsEdited(true)
                                          setCredit(_credit)
                                        }}
                                      />
                                      {timeDiff < 0 ? (
                                        <span className="float-end">
                                          <img
                                            src="/images/icons/green-check.svg"
                                            alt="green-check"
                                          />
                                        </span>
                                      ) : null}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="stadium-namediv marbot30">
                                <div className="font22">
                                  <img
                                    src="/images/icons/calander.svg"
                                    alt="tiket"
                                    className="me-2"
                                  />
                                  {moment(game?.game_time).format("MM/DD/Y")}
                                </div>
                                <div className="font22 mt-2">
                                  <img
                                    src="/images/icons/time.svg"
                                    alt="tiket"
                                    className="me-2"
                                  />
                                  {moment(game?.game_time).format("hh:mm A")}
                                </div>
                                <div className="font24 text-black font-w500 mt-3">
                                  {game?.place_name}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div>
                                <div className="mb-3">
                                  <img
                                    src={generateFileUrl(
                                      game.ContestRoundGameTeams[1].Team?.logo
                                    )}
                                    alt="soccer-club"
                                    className="img-fluid team-image"
                                  />
                                </div>
                                <div className="my-2 font17 text-black">
                                  <RemoteLanguage
                                    field={
                                      game?.ContestRoundGameTeams[1]?.Team?.name
                                    }
                                  />
                                </div>
                                {game.status !== "Pending" ? (
                                  <>
                                    {/* {game?.ContestRoundGameTeams[1]?.is_winner ?
                                                                            <div className="my-2 font24 font-w600 text-win">{t("WIN")}</div>
                                                                            : <div className="my-2 font24 font-w600 text-lost">{t("LOST")}</div>} */}

                                    {/* {game?.ContestRoundGameTeams[1]?.ContestRoundParticipant ?
                                                                            <> */}
                                    {/* <div className="font20 font-w500 winicon">
                                                                                    <img src={game?.ContestRoundGameTeams[1]?.is_winner ? "/images/icons/win.svg" : "/images/icons/lost.svg"} alt="win" />
                                                                                    <span className="font32">{game?.ContestRoundGameTeams[1]?.ContestRoundParticipant.credit}</span>/{game?.ContestRoundGameTeams[1]?.ContestRoundParticipant.credit}
                                                                                </div> */}

                                    <div className="font20 font-w500 winicon d-flex justify-content-center align-items-center">
                                      <img
                                        height={"25px"}
                                        className="pe-2"
                                        src={
                                          game?.ContestRoundGameTeams[1]
                                            ?.is_winner
                                            ? "/images/icons/green-check.svg"
                                            : "/images/icons/x-circle-red.svg"
                                        }
                                        alt="win"
                                      />
                                      <span className="font32">
                                        {game?.ContestRoundGameTeams[1]
                                          ?.ContestRoundParticipant?.credit
                                          ? game?.ContestRoundGameTeams[1]
                                              ?.ContestRoundParticipant?.credit
                                          : 0}
                                      </span>
                                    </div>

                                    {/* </>
                                                                            : null} */}
                                  </>
                                ) : (
                                  <div className="font20 font-w500">
                                    <img
                                      src="/images/icons/tiket.svg"
                                      alt="tiket"
                                      className="me-1"
                                    />
                                    <span className="tiketcheck">
                                      <input
                                        type="number"
                                        min={0}
                                        defaultValue={0}
                                        disabled={timeDiff < 0}
                                        onWheel={(event) =>
                                          event.currentTarget.blur()
                                        }
                                        onKeyDown={preventNegativeValues}
                                        onPaste={(e: any) => {
                                          e.preventDefault()
                                          return false
                                        }}
                                        className={
                                          totalCredit >
                                          contestRoundById?.round_credit
                                            ? "contest-input error"
                                            : "contest-input"
                                        }
                                        value={
                                          credit[
                                            game?.ContestRoundGameTeams[1]
                                              ?.contest_round_game_team_id
                                          ]?.credit
                                            ? credit[
                                                game?.ContestRoundGameTeams[1]
                                                  ?.contest_round_game_team_id
                                              ]?.credit
                                            : game?.ContestRoundGameTeams[1]
                                                ?.ContestRoundParticipant
                                                ?.credit
                                        }
                                        onChange={(e: any) => {
                                          let _credit = { ...credit }
                                          _credit[
                                            game?.ContestRoundGameTeams[1]?.contest_round_game_team_id
                                          ] = {
                                            contest_round_game_team_id:
                                              game?.ContestRoundGameTeams[1]
                                                ?.contest_round_game_team_id,
                                            credit: Number(
                                              e.target.value
                                            ).toString(),
                                          }
                                          setIsEdited(true)
                                          setCredit(_credit)
                                        }}
                                      />
                                      {timeDiff < 0 ? (
                                        <span className="float-end">
                                          <img
                                            src="/images/icons/green-check.svg"
                                            alt="green-check"
                                          />
                                        </span>
                                      ) : null}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  )}
                </>
              ) : (
                <>
                  {currentRoundData?.ContestRoundGames?.map(
                    (game: any, index: number) => {
                      var a = moment()
                      var b = moment(game.open_till)
                      const timeDiff = b.diff(a)
                      return (
                        <div
                          key={`game_${game.game_id}`}
                          className="ronded-gridview-main mb-5"
                        >
                          <div className="openallocation font20">
                            {timeDiff < 0
                              ? t("Allocation Closed AT")
                              : t("Open For Allocation Till")}
                            .{" "}
                            <span className="text-black font-w500">
                              {moment(game.open_till).format(
                                "hh:mm A, MM/DD/Y"
                              )}
                            </span>
                          </div>
                          <div className="stadium-name mb-2">
                            <div className="row align-items-center">
                              <div className="col-md-6">
                                <div className="font24 text-black font-w500">
                                  {game.place_name}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <ul className="dateandtime-grid">
                                  <li>
                                    <div className="font22">
                                      <img
                                        src="/images/icons/calander.svg"
                                        alt="tiket"
                                        className="me-2"
                                      />
                                      {moment(game.game_time).format("MM/DD/Y")}
                                    </div>
                                  </li>
                                  <li>
                                    <div className="font22">
                                      <img
                                        src="/images/icons/time.svg"
                                        alt="tiket"
                                        className="me-2"
                                      />
                                      {moment(game.game_time).format("hh:mm A")}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            {game.ContestRoundGameTeams.map(
                              (team: any, index: number) => {
                                return (
                                  <div
                                    className="col-lg-3 col-md-6"
                                    key={`game_${team.contest_round_game_team_id}`}
                                  >
                                    <div className="ronded-gridview">
                                      <div className="mb-3">
                                        <img
                                          src={generateFileUrl(
                                            team?.Team?.logo
                                          )}
                                          alt="photo"
                                          className="radius100 img100"
                                        />
                                      </div>
                                      <div className="my-2 font17 text-black">
                                        <RemoteLanguage
                                          field={team?.Team?.name}
                                        />
                                      </div>

                                      {game.status !== "Pending" ? (
                                        <>
                                          {/* {team?.is_winner ?
                                                                                    <div className="my-2 font24 font-w600 text-win">{t("WIN")}</div>
                                                                                    : <div className="my-2 font24 font-w600 text-lost">{t("LOST")}</div>
                                                                                } */}

                                          {/* {team?.ContestRoundParticipant ?
                                                                                    <> */}
                                          {/* <div className="font20 font-w500 winicon">
                                                                                            <img src={team?.is_winner ? "/images/icons/win.svg" : "/images/icons/lost.svg"} alt="win" />
                                                                                            <span className="font32">{team?.ContestRoundParticipant.credit}</span>/{team?.ContestRoundParticipant.credit}
                                                                                        </div> */}

                                          <div className="font20 font-w500 winicon d-flex justify-content-center align-items-center">
                                            <img
                                              height={"25px"}
                                              className="pe-2"
                                              src={
                                                game?.ContestRoundGameTeams[1]
                                                  ?.is_winner
                                                  ? "/images/icons/green-check.svg"
                                                  : "/images/icons/x-circle-red.svg"
                                              }
                                              alt="win"
                                            />
                                            <span className="font32">
                                              {game?.ContestRoundGameTeams[1]
                                                ?.ContestRoundParticipant
                                                ?.credit
                                                ? game?.ContestRoundGameTeams[1]
                                                    ?.ContestRoundParticipant
                                                    ?.credit
                                                : 0}
                                            </span>
                                          </div>
                                          {/* </>
                                                                                    : null} */}
                                        </>
                                      ) : (
                                        <div className="font20 font-w500">
                                          {" "}
                                          <img
                                            src="/images/icons/tiket.svg"
                                            alt="tiket"
                                            className="me-1"
                                          />{" "}
                                          <span className="tiketcheck">
                                            <input
                                              type="number"
                                              min={0}
                                              defaultValue={0}
                                              disabled={timeDiff < 0}
                                              onWheel={(event) =>
                                                event.currentTarget.blur()
                                              }
                                              onKeyDown={preventNegativeValues}
                                              onPaste={(e: any) => {
                                                e.preventDefault()
                                                return false
                                              }}
                                              className={
                                                totalCredit >
                                                contestRoundById?.round_credit
                                                  ? "contest-input error"
                                                  : "contest-input"
                                              }
                                              value={
                                                credit[
                                                  team
                                                    .contest_round_game_team_id
                                                ]?.credit
                                              }
                                              onChange={(e: any) => {
                                                let _credit = { ...credit }
                                                _credit[
                                                  team.contest_round_game_team_id
                                                ] = {
                                                  contest_round_game_team_id:
                                                    team.contest_round_game_team_id,
                                                  credit: Number(
                                                    e.target.value
                                                  ).toString(),
                                                }
                                                setIsEdited(true)
                                                setCredit(_credit)
                                              }}
                                            />

                                            {timeDiff < 0 ? (
                                              <span className="float-end">
                                                <img
                                                  src="/images/icons/green-check.svg"
                                                  alt="green-check"
                                                />
                                              </span>
                                            ) : null}
                                          </span>{" "}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )
                              }
                            )}
                          </div>
                        </div>
                      )
                    }
                  )}
                </>
              )}

              {currentRoundData?.ContestRoundGames?.length > 0 &&
              currentRoundData?.status !== "Close" ? (
                <>
                  {totalCredit > contestRoundById?.round_credit ? (
                    <div className="d-flex justify-content-center error text-danger">
                      {t("You can not assign more credit")}
                    </div>
                  ) : null}
                  <div className="d-flex justify-content-center">
                    <button
                      disabled={!isEdited}
                      onClick={() => {
                        onParticipate()
                      }}
                      className="btn btn-orange d-block w-25 radius25 mt-3 contest-submit"
                    >
                      {t("submit")}
                    </button>
                  </div>
                </>
              ) : null}

              {currentRoundData?.ContestRoundGames?.length === 0 && (
                <div className="text-center">
                  <div className="font-w300 font22 mb-5">
                    {t("Currently no games available for this round.")}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isModelOpen === false &&
      showCloseModel &&
      contestById.contest_id == params.id &&
      contestRoundById.contest_round_id == currentRoundData.contest_round_id ? (
        <>
          <Modal show={true}>
            <Modal.Body>
              <div className="text-center">
                <img src="/images/win-thumb.png" />
              </div>

              <div className="font-w500 mb-2 mt-3 font20 text-center">
                {t("Thank you for playing!")}
              </div>

              <div className="text-center">
                {t(
                  "Unfortunately you've lost all of your entries. Please keep your eye out for future promotional contests and enjoy the rest of our website!"
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={() => {
                  setIsModelOpen(true)
                  setShowCloseModel(false)
                }}
                className="btn btn-orange"
              >
                {t("Close")}
              </button>
              <button
                onClick={() => navigation("/")}
                className="btn btn-orange"
              >
                {t("Home")}
              </button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <></>
      )}

      <>
        <Modal
          size="lg"
          show={
            contestById.contest_id == params.id &&
            contestRoundById.contest_round_id ==
              currentRoundData.contest_round_id &&
            showWonModel &&
            isModelOpen === false
          }
        >
          <Modal.Body>
            <div className="row text-center">
              <div className="col-md-12 mt-5">
                <div>
                  <img
                    src="/images/icons/congratulations.svg"
                    alt="congratulations"
                    className="img-fluid m-auto"
                  />
                </div>
                <div className="font30 font-w500 mt-4 mb-3">
                  {t("Congratulations You have won!")}
                </div>
                <div>
                  <span className="max-prize font20 font-w700">
                    {numberFormat(contestById?.price_pool * creditWon)}
                  </span>
                </div>
                <div className="font18 mt-5">
                  <div>
                    <div>
                      <RemoteLanguage field={contestById.check_info} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => {
                setIsModelOpen(true)
                setShowWonModel(false)
              }}
              className="btn btn-orange"
            >
              {t("Close")}
            </button>
            <button onClick={() => navigation("/")} className="btn btn-orange">
              {t("Home")}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    </section>
  )
}
export default ContestDetail
