import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  getAchievementProduct,
  getProducts,
  getShippingCost,
} from "../api/appApi"

const storeInLocaleStorage = (product: any) => {
  localStorage.setItem("cardItem", JSON.stringify(product))
}

export const getProductsAsync = createAsyncThunk("allProducts", async () => {
  const response = await getProducts()
  return response.data
})

export const getShippingCostAsync = createAsyncThunk(
  "ShippingCost",
  async (data: any) => {
    const response = await getShippingCost(data)
    return response.data
  }
)

export const getAchievementProductAsync = createAsyncThunk(
  "AchievementProducts",
  async () => {
    const response = await getAchievementProduct()
    return response.data
  }
)

const cartSlice : any = createSlice({
  name: "cart",
  initialState: {
    cart: localStorage.getItem("cardItem")
      ? JSON.parse(localStorage.getItem("cardItem"))
      : [],
    totalCount: localStorage.getItem("cardItem")
      ? JSON.parse(localStorage.getItem("cardItem")).length
      : 0,
    allProducts: [],
    finalShippingCost: 0,
    status: "",
  },
  reducers: {
    getCartProducts: (state, action) => {
      state.totalCount = state.cart.length
      state.cart = JSON.parse(localStorage.getItem("cardItem"))
    },
    addToCart: (state, action) => {
      var _isAlreadyExist = false
      for (let i = 0; i < state.cart.length; i++) {
        if (
          state.cart[i].variant_id == action.payload.variant_id &&
          state.cart[i].product_id == action.payload.product_id
        ) {
          state.cart[i].quantity =
            state.cart[i].quantity + action.payload.productQuantity
          storeInLocaleStorage(state.cart)
          _isAlreadyExist = true
          break
        }
      }
      if (!_isAlreadyExist) {
        state.cart.push({ ...action.payload })
        state.totalCount++
        storeInLocaleStorage(state.cart)
      }
    },
    incrementQuantity: (state, action) => {
      state.cart[action.payload.index].quantity++
      storeInLocaleStorage(state.cart)
    },
    decrementQuantity: (state, action) => {
      state.cart[action.payload.index].quantity--
      storeInLocaleStorage(state.cart)
    },
    removeproduct: (state, action) => {
      state.cart.splice(action.payload.index, 1)
      state.totalCount--
      storeInLocaleStorage(state.cart)
    },
    getCartCount: (state, action) => {
      state.totalCount = state.cart.length
    },
  },
  extraReducers: (builder) => {
    // get Products List
    builder.addCase(getProductsAsync.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getProductsAsync.fulfilled, (state, action) => {
      state.status = "idle"
      state.allProducts = action.payload.data
    })
    builder.addCase(getProductsAsync.rejected, (state) => {
      state.status = "failed"
    })

    // get Shopping Cost
    builder.addCase(getShippingCostAsync.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getShippingCostAsync.fulfilled, (state, action) => {
      state.status = "idle"
      state.finalShippingCost = action.payload.standard / 100
    })
    builder.addCase(getShippingCostAsync.rejected, (state) => {
      state.status = "failed"
    })

    // get Achievement Products List
    builder.addCase(getAchievementProductAsync.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getAchievementProductAsync.fulfilled, (state, action) => {
      state.status = "idle"
      state.allProducts = action.payload.data
    })
    builder.addCase(getAchievementProductAsync.rejected, (state) => {
      state.status = "failed"
    })
  },
})

export const cartReducer = cartSlice.reducer
export const {
  addToCart,
  incrementQuantity,
  decrementQuantity,
  removeproduct,
  getCartCount,
  getCartProducts,
} = cartSlice.actions

export default cartSlice.reducer
