import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import {
  videoState,
  getVideoByIdAsync,
  resetVideoAccessBuy,
} from "../stores/videoSlice"
import { useParams } from "react-router-dom"
import { t } from "i18next"
import Vimeo from "@u-wave/react-vimeo"

export const VideoDetail = () => {
  const dispatchApp = useAppDispatch()
  const params = useParams()
  const { status, videoDetail, videoAccessBuy } = useAppSelector(videoState)

  useEffect(() => {
    dispatchApp(getVideoByIdAsync(params.id))
  }, [params.id])

  return (
    <section className="contentpart">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-lg-10">
            <div className="roundeddiv mt-4">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <div className="position-relative contests-reg pb-2 mb-3 font50">
                    {videoDetail?.title}
                  </div>
                  <div className="ariclebyname mt-3">
                    {t("by")} {videoDetail?.author}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <p>{videoDetail?.description}</p>
            {videoDetail?.video_link && (
              <div className="text-center pt-2">
                <Vimeo
                  video={videoDetail?.video_link}
                  className="img-fluid vimeo-video h-100 w-100"
                  loop={true}
                  responsive={true}
                  pip={true}
                  showTitle={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
