import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getPodcastList, getPodcastById, PodcastAccessBuy } from "../api/appApi"

export interface AppStateInterface {
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted"
  podcastList: any
  PodcastListByLangCode: any
  podcastDetail: any
  podcastAccessBuy: any
  popularPodcast: any
  podcastListCount: number
  showPerchaseModal: any
}

const initialState: AppStateInterface = {
  status: "idle",
  podcastList: [],
  PodcastListByLangCode: [],
  podcastDetail: {},
  podcastAccessBuy: {},
  popularPodcast: {},
  podcastListCount: 0,
  showPerchaseModal: { podcast_id: "", title: "", credit: "" },
}

export const getPodcastListAsync = createAsyncThunk(
  "PodcastList",
  async (params: any) => {
    const response = await getPodcastList(
      params.limit,
      params.page,
      params.language_id,
      params.filterValue,
      params.sport_id
    )
    return { params: params, data: response.data }
  }
)

export const podcastAccessBuyAsync = createAsyncThunk(
  "podcastAccessBuy",
  async (data: any) => {
    const response: any = await PodcastAccessBuy(data)
    return response.data
  }
)

export const getPodcastByIdAsync = createAsyncThunk(
  "PodcastById",
  async (id: string) => {
    const response = await getPodcastById(id)
    return response.data
  }
)

export const podcastSlice = createSlice({
  name: "podcast",
  initialState,

  reducers: {
    resetPodcastAccessBuy: (state) => {
      state.podcastAccessBuy = {}
      state.showPerchaseModal = { podcast_id: "", title: "", credit: "" }
    },
    resetPodcastList: (state) => {
      state.podcastList = []
    },
    setShowPerchaseModal: (state, action: PayloadAction<any>) => {
      state.showPerchaseModal = action.payload
    },
  },

  extraReducers: (builder) => {
    // get Podcast List
    builder.addCase(getPodcastListAsync.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getPodcastListAsync.fulfilled, (state, action) => {
      state.status = "idle"
      state.podcastListCount = action.payload.data.count

      if (action.payload.params.page == 1) {
        state.podcastList = action.payload.data.data
      } else {
        state.podcastList = [...state.podcastList, ...action.payload.data.data]
      }
    })
    builder.addCase(getPodcastListAsync.rejected, (state) => {
      state.status = "failed"
    })

    // Podcast Access Buy
    builder.addCase(podcastAccessBuyAsync.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(podcastAccessBuyAsync.fulfilled, (state, action) => {
      state.status = "idle"
      state.podcastAccessBuy = action.payload
    })
    builder.addCase(podcastAccessBuyAsync.rejected, (state) => {
      state.status = "failed"
    })

    // get Podcast By ID
    builder.addCase(getPodcastByIdAsync.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getPodcastByIdAsync.fulfilled, (state, action) => {
      state.status = "idle"
      state.podcastDetail = action.payload.data
    })
    builder.addCase(getPodcastByIdAsync.rejected, (state) => {
      state.status = "failed"
    })
  },
})

export const { resetPodcastAccessBuy, resetPodcastList, setShowPerchaseModal } =
  podcastSlice.actions
export const podcastState = (state: any) => state.podcast
export default podcastSlice.reducer
