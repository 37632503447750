import { Modal } from "react-bootstrap"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate, useParams } from "react-router-dom"
import Articals from "../component/articals"
import HomePageTab from "../component/hometabs"
import { paymentCancle, paymentSuccess, productSuccess } from "../api/appApi"
import { useAppDispatch } from "../stores/hooks"
import { getProfileAsync } from "../stores/appSlice"
import { showErrorToast } from "../utils/commonFun"

const StripeCallBack = () => {
  const [showWarningModel, setShowWarningModel] = useState(false)
  const { t } = useTranslation()
  const params = useParams()
  const dispatchApp = useAppDispatch()
  const [responseStatus, setResponseStatus] = useState("loading")
  const [isProduct, setIsProduct] = useState("")

  useEffect(() => {
    checkPaymentStatus()
  }, [params.status])

  const checkPaymentStatus = async () => {
    if (localStorage.getItem("cs_id")) {
      setIsProduct("credit")
    } else {
      setIsProduct("product")
    }

    if (
      localStorage.getItem("cs_id")
        ? localStorage.getItem("cs_id")
        : localStorage.getItem("product_cs_id")
    ) {
      if (params.status === "success") {
        if (localStorage.getItem("cs_id")) {
          paymentSuccess(localStorage.getItem("cs_id"))
            .then((res) => {
              if (res.data.statusCode == 200 || res.data.statusCode == 201) {
                setResponseStatus("success")
                dispatchApp(getProfileAsync())
              } else {
                setResponseStatus("wrong")
              }
            })
            .catch((error) => {
              setResponseStatus("wrong")
            })
        } else {
          productSuccess(localStorage.getItem("product_cs_id"))
            .then((res) => {
              if (res.data.statusCode == 200 || res.data.statusCode == 201) {
                setResponseStatus("success")
                localStorage.removeItem("cardItem")
                dispatchApp(getProfileAsync())
              } else {
                showErrorToast(res.data.message)
                setResponseStatus("wrong")
              }
            })
            .catch((error) => {
              setResponseStatus("wrong")
            })
        }
      } else {
        paymentCancle(
          localStorage.getItem("cs_id")
            ? localStorage.getItem("cs_id")
            : localStorage.getItem("product_cs_id")
        )
          .then((res) => {
            if (res.data.statusCode == 200 || res.data.statusCode == 201) {
              setResponseStatus("cancel")
              dispatchApp(getProfileAsync())
            } else {
              setResponseStatus("wrong")
            }
          })
          .catch((error) => {
            setResponseStatus("wrong")
          })
      }
      localStorage.removeItem("product_cs_id")
      localStorage.removeItem("cs_id")
    } else {
      setResponseStatus("wrong")
    }
  }

  const navigation = useNavigate()
  return (
    <section className="contentpart min-height">
      <div className="contest-box position-relative">
        <div className="container">
          <div className="row justify-content-center text-center">
            {responseStatus === "success" ? (
              <div className="col-lg-12">
                <div className="position-relative contests-reg pb-2 mb-3 text-center">
                  <span className="font-w300 font45"> {t("Thank you")} </span>
                </div>
                {localStorage.getItem("cs_id") ? (
                  <div className="mt-5 mb-5">
                    {" "}
                    {t(
                      "Thank you for your payment, it has been received. Your account will be credit with you media credits immediately! Enjoy our site"
                    )}
                  </div>
                ) : (
                  <div className="mt-5 mb-5">
                    {" "}
                    {t(
                      "Thank you for your payment, it has been received. Your order will be deliveried."
                    )}
                  </div>
                )}
              </div>
            ) : responseStatus === "wrong" || responseStatus === "cancel" ? (
              <div className="col-lg-12">
                <div className="position-relative contests-reg pb-2 mb-3 text-center">
                  <span className="font-w300 font45">
                    {" "}
                    {t("Purchase Abandoned")}{" "}
                  </span>
                </div>
                <div className="mt-5 mb-5">
                  {" "}
                  {t("Are you sure you would like to abandon your purchase?")}
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {isProduct == "credit" ? (
              <ul className="buy-media">
                <li>
                  <button
                    onClick={() => {
                      navigation("/")
                    }}
                    className="btn btn-orange box-shadow"
                  >
                    {" "}
                    {t("Ok")}{" "}
                  </button>
                </li>
                <li>
                  <Link
                    to="/buy-media-credit"
                    className="btn btn-orange box-shadow"
                  >
                    {t("buy_media_credits")}
                  </Link>
                </li>
              </ul>
            ) : isProduct == "product" ? (
              <ul className="buy-media">
                <li>
                  <button
                    onClick={() => {
                      navigation("/")
                    }}
                    className="btn btn-orange box-shadow"
                  >
                    {" "}
                    {t("Ok")}{" "}
                  </button>
                </li>
                <li>
                  <Link to="/store" className="btn btn-orange box-shadow">
                    {t("continue_shopping")}
                  </Link>
                </li>
              </ul>
            ) : (
              <></>
            )}
            {/* <HomePageTab /> */}
          </div>
        </div>
      </div>
    </section>
  )
}

export default StripeCallBack
