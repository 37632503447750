import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { myOrders } from "../api/appApi"

export const getMyOrdersAsync = createAsyncThunk(
  "getMyOrders",
  async () => {
    const response = await myOrders()
    return response?.data
  }
)

const myOrdersSlice = createSlice({
  name: "myorders",
  initialState: {
    orders: [],
    status: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    // get Products List
    builder.addCase(getMyOrdersAsync.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getMyOrdersAsync.fulfilled, (state, action) => {
      state.status = "idle"
      state.orders = action.payload.data.rows
    })
    builder.addCase(getMyOrdersAsync.rejected, (state) => {
      state.status = "failed"
    })
  },
})

export const {} = myOrdersSlice.actions
export const myOrderState = (state: any) => state.myoders;
export default myOrdersSlice.reducer
