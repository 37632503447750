import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  appState,
  getProfileAsync,
  resetUpdateProfile,
  updateProfileAsync,
} from "../stores/appSlice"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import uploadFileToBlob from "../utils/AzureStorageBlob"
import AxiosInstance from "../utils/AxiosInstance"
import { useNavigate } from "react-router-dom"
import { generateFileUrl } from "../utils/AzureStorageBlob"
import { showErrorToast, showSuccessToast } from "../utils/commonFun"
import ErrorAlert from "../component/errorAlert"
import {
  getAchievementsAsync,
  getActivityAsync,
  myAccountState,
} from "../stores/myAccountSlice"
import RemoteLanguage from "../component/remoteLang"
import CloseContest from "../component/closeContest"

export const MyAccount = () => {
  const [viewType, setViewType] = useState("grid")
  const [activeTab, setActiveTab] = useState("Profile")
  const [showProfileTab, setShowProfileTab] = useState(false)
  const { t } = useTranslation()
  const [serverErrorMessage, setServerErrorMessage] = useState("")
  const [profilePageTab, setProfilePageTab] = useState("activity")
  const [profileImage, setProfileImage] = useState("")
  const { profile, updateProfile, status } = useAppSelector(appState)
  const { activity, achievements } = useAppSelector(myAccountState)
  const dispatchApp = useAppDispatch()
  const navigate = useNavigate()
  const [limit, setLimit] = useState(3)
  const [page, setPage] = useState(1)

  const ProfileSchema = Yup.object().shape({
    nick_name: Yup.string()
      .min(3, t("Nick name is too Short!"))
      .max(20, t("Nick name too Long!"))
      .required(t("Nick name required")),
    birth_year: Yup.number()
      .required()
      .moreThan(1900, t("Year Of Birth should not be 1900 or less than 1900"))
      .lessThan(2024, t("Year Of Birth should not be more than 2023"))
      .required(t("date-of-birth-required")),
    // full_name: Yup.string().required(t("Full name required")),
    // address: Yup.string().required(t("Address required")),
  })

  const update2FA = async (field: any, value: any) => {
    const data = {
      _2fa: value,
    }
    const res: any = await AxiosInstance.put("/users/update-2fa/", data)
    dispatchApp(getProfileAsync())
  }

  const onSubmitProfileForm = async (values: any, actions: any) => {
    setServerErrorMessage("")
    const data = {
      nick_name: values.nick_name,
      full_name: values.full_name,
      profile_image: values.profile_image,
      _2fa: values._2fa,
      address: values.address ? values.address : "",
      birth_year: values.birth_year,
    }
    dispatchApp(updateProfileAsync(data))
  }

  useEffect(() => {
    if (updateProfile.statusCode == 200 || updateProfile.statusCode == 201) {
      setShowProfileTab(!showProfileTab)
      showSuccessToast(updateProfile.message)
      dispatchApp(resetUpdateProfile())
    } else if (updateProfile.statusCode == 400) {
      showErrorToast(updateProfile.message)
    }
  }, [updateProfile])

  useEffect(() => {
    if (profile?.profile_image) {
      const fileUrl = generateFileUrl(profile?.profile_image)
      setProfileImage(fileUrl)
    } else {
      setProfileImage("/images/user.png")
    }
  }, [profile])

  const handleLogout = () => {
    localStorage.removeItem("token")
    navigate("/")
  }

  useEffect(() => {
    dispatchApp(getActivityAsync())
    const data = {
      page: page,
      limit: limit,
    }
    dispatchApp(getAchievementsAsync(data))
  }, [page, limit])

  return (
    <div className="contentpart">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="white-box profilebox p-4 mb-4 mt-4">
              <div className="eidticon">
                <a
                  className="btn btn-orange"
                  onClick={() => {
                    setShowProfileTab(!showProfileTab)
                  }}
                >
                  {!showProfileTab ? (
                    <img src="/images/icons/edit.svg" alt="edit" />
                  ) : (
                    <img src="/images/icons/close-x.svg" alt="edit" />
                  )}
                </a>

                <a
                  className="btn btn-orange ms-1"
                  onClick={() => {
                    handleLogout()
                  }}
                >
                  {t("logout")}
                </a>
              </div>

              {!showProfileTab ? (
                <div className="row justify-content-center pt-30">
                  <div className="col-lg-4">
                    <div className="text-center">
                      <img
                        width="285"
                        height="285"
                        src={
                          profile?.profile_image
                            ? generateFileUrl(profile?.profile_image)
                            : "/images/user.png"
                        }
                        alt="profile photo"
                        className="img-fluid1 m-auto rounded-circle"
                      />
                    </div>
                  </div>

                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="mb-3">
                          <label>{t("Username")}</label>
                          <div className="font24 text-black font-w300">
                            {profile?.nick_name}
                          </div>
                        </div>
                        <div className="mb-3">
                          <label>{t("Email")}</label>
                          <div className="font24 text-black font-w300">
                            {profile?.email}
                          </div>
                        </div>
                        <div className="mb-3">
                          <label>{t("media-credits")}</label>
                          <ul className="buy-media">
                            <li>
                              <a className="link-black">
                                <span>
                                  <img
                                    src="/images/icons/camera.svg"
                                    alt="shop"
                                  />
                                </span>
                                {profile?.media_credit}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="mb-3">
                          <label>{t("full_name")}</label>
                          <div className="font24 text-black font-w300">
                            {profile?.full_name}
                          </div>
                        </div>
                        <div className="mb-3">
                          <label>{t("Address")}</label>
                          <div className="font24 text-black font-w300">
                            {profile?.address}
                          </div>
                        </div>
                        <div className="mb-3">
                          <label>{t("Year of Birth")}</label>
                          <div className="font24 text-black font-w300">
                            {profile?.birth_year}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-12">
                        <ul className="two-authentication">
                          <li className="line">
                            <div className="font15 font-w500 text-end">
                              {t("Two Factor")}
                              <br />
                              {t("Authentication")}
                            </div>
                          </li>
                          <li>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={profile?._2fa}
                                onChange={(e) => {
                                  update2FA("_2fa", e.target.checked)
                                }}
                              />
                              <span className="slider round"></span>{" "}
                            </label>
                          </li>
                        </ul>
                        <div className="flclear"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Formik
                  initialValues={{
                    nick_name: profile?.nick_name,
                    full_name: profile?.full_name,
                    _2fa: profile?._2fa,
                    profile_image: profile?.profile_image,
                    address: profile?.address,
                    birth_year: profile?.birth_year,
                  }}
                  validationSchema={ProfileSchema}
                  onSubmit={onSubmitProfileForm}
                >
                  {({ errors, touched, setFieldValue }) => {
                    return (
                      <Form>
                        <div className="row justify-content-center pt-30">
                          {serverErrorMessage ? (
                            <div className="alert alert-danger">
                              {serverErrorMessage}
                            </div>
                          ) : null}

                          <div className="col-lg-4">
                            <div className="text-center position-relative">
                              <img
                                width="285"
                                height="285"
                                src={profileImage}
                                alt="profile photo"
                                className="img-fluid1 m-auto rounded-circle"
                              />
                              <div className="upload-photo pointer">
                                <a href="#">
                                  <img
                                    src="/images/icons/camera.svg"
                                    alt="shop"
                                  />
                                </a>
                                <input
                                  name="profile_image"
                                  type="file"
                                  className="upload-profile"
                                  onChange={async (event: any) => {
                                    const res = await uploadFileToBlob(
                                      event.target.files[0],
                                      "images"
                                    )
                                    setFieldValue("profile_image", res)
                                    const fileUrl = generateFileUrl(res)
                                    setProfileImage(fileUrl)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <div className="row">
                              <ErrorAlert
                                errors={errors}
                                touched={touched}
                                className="mt-3"
                              />
                              <div className="col-sm-6">
                                <div className="mb-3">
                                  <label>{t("Username")}</label>
                                  <Field
                                    name="nick_name"
                                    type="text"
                                    className={
                                      errors.nick_name && touched.nick_name
                                        ? "form-control error"
                                        : "form-control"
                                    }
                                    placeholder={t("enter_your_nick_name")}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label>{t("Email")}</label>
                                  <div className="font24 text-black font-w300">
                                    <Field
                                      name="email"
                                      type="text"
                                      value={profile?.email}
                                      disabled
                                      className={"form-control"}
                                      placeholder={t("enter_your_email")}
                                    />
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <label>{t("media-credits")}</label>
                                  <Field
                                    name="media_credit"
                                    type="text"
                                    value={profile?.media_credit}
                                    disabled
                                    className={"form-control"}
                                    placeholder={t("enter_your_email")}
                                  />
                                  {/* </ul> */}
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="mb-3">
                                  <label>{t("full_name")}</label>
                                  <Field
                                    name="full_name"
                                    type="text"
                                    className={
                                      errors.full_name && touched.full_name
                                        ? "form-control error"
                                        : "form-control"
                                    }
                                    placeholder={t("enter_your_full_name")}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label>{t("Address")}</label>
                                  <Field
                                    name="address"
                                    as="textarea"
                                    placeholder={t("enter-your-address")}
                                    className={
                                      errors.address && touched.address
                                        ? "form-control error"
                                        : "form-control"
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="mb-3">
                                  <label>{t("Year of Birth")}</label>
                                  <Field
                                    name="birth_year"
                                    type="number"
                                    className={
                                      errors.birth_year && touched.birth_year
                                        ? "form-control error"
                                        : "form-control"
                                    }
                                    disabled={profile.birth_year}
                                    placeholder={t("Enter Your Birth Year")}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="d-flex justify-content-center">
                              <button
                                type="submit"
                                className="btn btn-orange d-block w-25 radius25 mt-3"
                              >
                                {t("update_profile")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              )}
            </div>

            <div className="white-box profilebox p-4 mt-5 mb-4">
              <div id="ProfilePageTab" className="profilepagetab">
                <ul className="resp-tabs-list hor_1">
                  <li
                    className={
                      profilePageTab === "activity" && "resp-tab-active"
                    }
                    onClick={() => {
                      setProfilePageTab("activity")
                    }}
                  >
                    {" "}
                    <img
                      src="/images/icons/activity.svg"
                      alt="Activity"
                      height="24"
                      className="me-2"
                    />{" "}
                    {t("Activity")}{" "}
                  </li>
                  <li
                    className={
                      profilePageTab === "achievements" && "resp-tab-active"
                    }
                    onClick={() => {
                      setProfilePageTab("achievements")
                    }}
                  >
                    {" "}
                    <img
                      src="/images/icons/achievements.svg"
                      alt="Achievements"
                      className="me-2"
                    />{" "}
                    {t("Achievements")}{" "}
                  </li>
                </ul>
                <div className="resp-tabs-container hor_1 contests-sec">
                  {profilePageTab === "activity" ? (
                    <div className="activity-tab-info">
                      <div className="row mt-3 text-center">
                        <div className="col-md-6">
                          <div>
                            <ul className="buy-media">
                              <li>
                                <a href="#" className="link-black">
                                  <span>
                                    <img
                                      src="/images/icons/camera.svg"
                                      alt="shop"
                                    />
                                  </span>
                                  {t("LIFETIME MEDIA CREDITS USED -")}{" "}
                                  {Math.round(activity?.data?.mediaCredit)}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="text-center">
                            <ul className="buy-media">
                              <li>
                                <a href="#" className="link-black">
                                  <span>
                                    <img
                                      src="/images/icons/cart.svg"
                                      alt="shop"
                                    />
                                  </span>
                                  {t("LIFETIME PURCHASES IN SHOP -")}{" "}
                                  {Math.round(activity?.data?.shoppingAmount / 100)}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="hrline"></div>
                      <div className="row">
                        {activity?.data?.win?.rows?.map(
                          (contest: any, index: number) => {
                            return (
                              <div className="col-md-4">
                                <div className="important-notes font15">
                                  <ul>
                                    <li>
                                      {t("CHAMPION")} -{" "}
                                      <RemoteLanguage field={contest?.title} />{" "}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )
                          }
                        )}
                        {activity?.data?.contest?.rows?.map(
                          (contest: any, index: number) => {
                            return (
                              <div className="col-md-4">
                                <div className="important-notes font15">
                                  <ul>
                                    <li>
                                      {t("ENTRANT")} -{" "}
                                      <RemoteLanguage field={contest?.title} />{" "}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )
                          }
                        )}

                        {activity?.data?.contest?.rows?.length == 0 &&
                        activity?.data?.win?.rows?.length == 0 ? (
                          <div className="text-center font-w300 font22 mt-5 mb-5 ">
                            {t("No Activities Found")}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <div className="achievements-tab-info">
                      <div className="row">
                        {achievements?.length ? (
                          achievements?.map((achievement: any) => {
                            return (
                              <div className="col-lg-4 mt-3">
                                <div className="achievements">
                                  <img
                                    src={generateFileUrl(
                                      achievement?.event_image
                                    )}
                                    alt="achievement"
                                    className="img-fluid achievement-image mt-3"
                                  />
                                  <div className="article-title pt-2">
                                    {achievement?.event_title}
                                  </div>
                                  <div className="mb-4">
                                    {achievement?.event_description}
                                  </div>
                                  <a
                                    href={`/achievement/shop/${achievement?.catalog_url}`}
                                    className="btn btn-orange btn-shadow"
                                  >
                                    {t("Shop Now")}
                                  </a>
                                </div>
                              </div>
                            )
                          })
                        ) : (
                          <div className="text-center font-w300 font22 mt-5 mb-5 ">
                            {t("No Achievements Earned")}
                          </div>
                        )}
                      </div>
                      {achievements?.length == page * limit &&
                      !(status == "loading") ? (
                        <div className="mt-5 mb-4 text-center">
                          <a
                            onClick={() => {
                              setPage(page + 1)
                            }}
                            className="btn btn-orange box-shadow view-more-btn"
                          >
                            {status == "loading"
                              ? t("loading")
                              : t("view-more")}{" "}
                          </a>
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="white-box profilebox p-4 mt-5 mb-4">
              <CloseContest />
            </div>
          </div>
        </div>
      </div>

      {/* {showProfileTab ? (
        <>
          <div id="HomePageTab" className="homepagetab">
            <div className="container">
              <div className="tabviewbar">
                <div className="row align-content-center">
                  <div className="col-lg-12">
                    <ul className="resp-tabs-list hor_1">
                      <li
                        onClick={() => setActiveTab("Profile")}
                        className={
                          activeTab == "Profile"
                            ? "resp-tab-item hor_1 resp-tab-active"
                            : "resp-tab-item hor_1"
                        }
                      >
                        {t("profile")}
                      </li>
                      <li
                        onClick={() => setActiveTab("BankDetails")}
                        className={
                          activeTab == "BankDetails"
                            ? "tablineleft resp-tab-item hor_1 resp-tab-active"
                            : "tablineleft resp-tab-item hor_1"
                        }
                      >
                        {t("bank_details")}
                      </li>
                      <li
                        onClick={() => setActiveTab("TransHis")}
                        className={
                          activeTab == "TransHis"
                            ? "resp-tab-item hor_1 resp-tab-active"
                            : "resp-tab-item hor_1"
                        }
                      >
                        {t("transaction_history")}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {activeTab === "Profile" ? (
              <Profile
                viewType={viewType}
                setShowProfileTab={setShowProfileTab}
              />
            ) : activeTab === "BankDetails" ? (
              <BankDetails viewType={viewType} />
            ) : (
              <TransHistory />
            )}
          </div>
        </>
      ) : null} */}
    </div>
  )
}
