import axios from "axios";
import config from "./config";
import store from "../stores";
import { setAppLoader } from "../stores/appSlice";

const baseURL = config.BASE_URL;

const AxiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 35000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

AxiosInstance.interceptors.request.use(
  async (config: any) => {
    axios.defaults.timeout = 35000;
    config.baseURL = baseURL;
    store.dispatch(setAppLoader(true));
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = "Bearer ".concat(token);
    }
    return config;
  },
  (error) => Promise.reject(error.response.data)
);


AxiosInstance.interceptors.response.use(
  async (response) => {
    store.dispatch(setAppLoader(false));
    return response;
  },
  (errors) => {
    store.dispatch(setAppLoader(false))
    if (errors.response.status === 401) {
      // localStorage.clear();
      localStorage.removeItem("token")
      window.location.reload()
    }
    return errors.response;
  }
);


export default AxiosInstance;
