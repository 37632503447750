import React from "react";
import { useTranslation } from "react-i18next"
import { Tooltip } from "react-tooltip";

const Rating = ({ complexity }: { complexity: number }) => {

    const { t } = useTranslation()

    if (complexity == 2) {
        return (<div className="font12 font-w500 mt-3">{t('difficulty_level')}
            <img src="/images/icons/star-yellow-dark.svg" alt="star-yellow" className="ms-2" />
            <img src="/images/icons/star-yellow-dark.svg" alt="star-red" className="ms-1" />
            <img src="/images/icons/star-gray.svg" alt="star-red" className="ms-1" />
            <img src="/images/icons/star-gray.svg" alt="star-red" className="ms-1" />
            <img src="/images/icons/star-gray.svg" alt="star-red" className="ms-1" />
            <a href="#!" onClick={(e) => { e.stopPropagation() }} data-tooltip-id="ratting-tooltip-1"><img src="/images/icons/info.svg" alt="info" className="ms-2" /></a>
            <Tooltip id="ratting-tooltip-1" content={t(`complexity_${complexity}`)} closeOnScroll openOnClick />

        </div>)
    } else if (complexity == 3) {
        return (<div className="font12 font-w500 mt-3">{t('difficulty_level')}
            <img src="/images/icons/star-orange.svg" alt="star-orange" className="ms-1" />
            <img src="/images/icons/star-orange.svg" alt="star-orange" className="ms-1" />
            <img src="/images/icons/star-orange.svg" alt="star-orange" className="ms-1" />
            <img src="/images/icons/star-gray.svg" alt="star-red" className="ms-1" />
            <img src="/images/icons/star-gray.svg" alt="star-red" className="ms-1" />
            <a href="#!" onClick={(e) => { e.stopPropagation() }} data-tooltip-id="ratting-tooltip-2"><img src="/images/icons/info.svg" alt="info" className="ms-2" /></a>
            <Tooltip id="ratting-tooltip-2" content={t(`complexity_${complexity}`)} closeOnScroll openOnClick />

        </div>)
    } else if (complexity == 4) {
        return (<div className="font12 font-w500 mt-3">{t('difficulty_level')}
            <img src="/images/icons/star-orange-dark.svg" alt="star-orange-dark" className="ms-1" />
            <img src="/images/icons/star-orange-dark.svg" alt="star-orange-dark" className="ms-1" />
            <img src="/images/icons/star-orange-dark.svg" alt="star-orange-dark" className="ms-1" />
            <img src="/images/icons/star-orange-dark.svg" alt="star-orange-dark" className="ms-1" />
            <img src="/images/icons/star-gray.svg" alt="star-red" className="ms-1" />
            <a href="#!" onClick={(e) => { e.stopPropagation() }} data-tooltip-id="ratting-tooltip-3"><img src="/images/icons/info.svg" alt="info" className="ms-2" /></a>
            <Tooltip id="ratting-tooltip-3" content={t(`complexity_${complexity}`)} closeOnScroll openOnClick />

        </div>)
    } else if (complexity == 5) {
        return (<div className="font12 font-w500 mt-3">{t('difficulty_level')}
            <img src="/images/icons/star-red.svg" alt="star-red" className="ms-1" />
            <img src="/images/icons/star-red.svg" alt="star-red" className="ms-1" />
            <img src="/images/icons/star-red.svg" alt="star-red" className="ms-1" />
            <img src="/images/icons/star-red.svg" alt="star-red" className="ms-1" />
            <img src="/images/icons/star-red.svg" alt="star-red" className="ms-1" />
            <a href="#!" onClick={(e) => { e.stopPropagation() }} data-tooltip-id="ratting-tooltip-4"><img src="/images/icons/info.svg" alt="info" className="ms-2" /></a>
            <Tooltip id="ratting-tooltip-4" content={t(`complexity_${complexity}`)} closeOnScroll openOnClick />

        </div>)
    }

    return (<div className="font12 font-w500 mt-3">{t('difficulty_level')}
        <img src="/images/icons/star-yellow.svg" alt="star-yellow" className="ms-2" />
        <img src="/images/icons/star-gray.svg" alt="star-red" className="ms-1" />
        <img src="/images/icons/star-gray.svg" alt="star-red" className="ms-1" />
        <img src="/images/icons/star-gray.svg" alt="star-red" className="ms-1" />
        <img src="/images/icons/star-gray.svg" alt="star-red" className="ms-1" />
        <a href="#!" data-tooltip-id="ratting-tooltip-5"><img src="/images/icons/info.svg" alt="info" className="ms-2" /></a>
        <Tooltip id="ratting-tooltip-5" content={t(`complexity_${complexity}`)} closeOnScroll openOnClick />

    </div>)

}

export default Rating