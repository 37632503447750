import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getContestRoundByContestId, participant } from "../api/appApi";

export interface AppStateInterface {
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted";
  contestRound: any;
  contestRoundById: any;
  contestRoundGames: any[];
  participant : {}

}

const initialState: AppStateInterface = {
  status: "idle",
  contestRound: [],
  contestRoundById: {},
  contestRoundGames: [],
  participant : {}
};

export const getContestRoundByContestIdAsync = createAsyncThunk("ContestRoundByRoundId", async (id: string) => {
  const response = await getContestRoundByContestId(id);
  return response.data;
});

export const participantAsync = createAsyncThunk("participant", async (data: any) => {
  const response = await participant(data);
  return response.data;
});


export const contestRoundSlice = createSlice({
  name: "contestRound",
  initialState,

  reducers: {},

  extraReducers: (builder) => {

    // get ContestRound By ID
    builder.addCase(getContestRoundByContestIdAsync.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(getContestRoundByContestIdAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.contestRoundById = action.payload.data[0];
      if(action.payload.data[0]){
        state.contestRoundGames = action.payload.data[0].ContestRoundGames
      }else{
        state.contestRoundGames = []
      }
      
    })
    builder.addCase(getContestRoundByContestIdAsync.rejected, (state) => {
      state.status = "failed";
    })
    
    // Create participant
    builder.addCase(participantAsync.pending, (state) => {
      state.status = "loading";
    })
    builder.addCase(participantAsync.fulfilled, (state, action) => {
      state.status = "idle";
      state.participant = action.payload;
    })
    builder.addCase(participantAsync.rejected, (state) => {
      state.status = "failed";
    })


  },
});

export const { } = contestRoundSlice.actions;
export const contestRoundState = (state: any) => state.contestRound;
export default contestRoundSlice.reducer;
