import React, { useEffect, useState } from "react"
import { appState, getHomeAsync, setShowModal } from "../stores/appSlice"
import { useTranslation } from "react-i18next"
import store from "../stores"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import { getHome } from "../api/appApi"
import AxiosInstance from "../utils/AxiosInstance"
import { generateFileUrl } from "../utils/AzureStorageBlob"
import { t } from "i18next"
import moment from "moment"
import { Link } from "react-router-dom"
import RemoteLanguage from "../component/remoteLang"
import { numberFormat } from "../utils/commonFun"

const Home = () => {
  const { languages, homeData } = useAppSelector(appState)
  const { i18n, t } = useTranslation()
  const dispatchApp = useAppDispatch()
  const setVisibleModel = (modelName: string) => {
    dispatchApp(setShowModal(modelName))
  }

  useEffect(() => {
    const found = languages?.data?.find(
      (element: any) => element.language_code == i18n.language
    )
    if (found?.language_id) {
      dispatchApp(getHomeAsync(found?.language_id))
    }
  }, [languages, i18n?.language])

  return (
    <>
      {true ? (
        <section className="contentpart p-0">
          <div className="content-contests-bg">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="con-and-contextbox my-5 my-sm-3">
                    <div className="row justify-content-center">
                      <div className="col-lg-10 col-md-10 col-sm-12">
                        <div className="row ">
                          <div className="col-lg-5">
                            <div className="ariclebox ariclebox-home ">
                              <img
                                src={generateFileUrl(homeData?.CM?.Sport?.logo)}
                                alt="article"
                              />
                              <div className="article-title">
                                {homeData?.CM?.title}
                              </div>
                              <div className="ariclebyname">
                                {t("by")} {homeData?.CM?.author_name}
                              </div>
                              <div>{homeData?.CM?.short_description}</div>
                              <div className="con-and-context text-orange">
                                {t("Content")}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="con-and-context text-orange text-center andtext">
                              &amp;
                            </div>
                          </div>
                          <div className="col-lg-5 mt60">
                            <div className="sports-list text-center">
                              <div className="home-sports-box-bg home-sports-box-white">
                                <div className="sportimg">
                                  <img
                                    src={generateFileUrl(
                                      homeData?.Contest?.banner
                                    )}
                                    alt="Football"
                                  />
                                </div>
                                <h2>
                                  <RemoteLanguage
                                    field={homeData?.Contest?.title}
                                  />
                                  {homeData?.Contest?.rules_file && (
                                    <Link
                                      to={generateFileUrl(
                                        homeData?.Contest?.rules_file
                                      )}
                                      target="_blank"
                                    >
                                      <img
                                        src="/images/icons/notepad.svg"
                                        alt="notepad"
                                        className="ms-1"
                                      />
                                    </Link>
                                  )}
                                </h2>
                                <span className="prize-per-winning-entry">
                                  {t("prize_per_winning_entry")}{" "}
                                  <span>
                                    {numberFormat(
                                      homeData?.Contest?.price_pool
                                    )}
                                  </span>
                                </span>
                                <div className="font-w500 text-black">
                                  <RemoteLanguage
                                    field={homeData?.Contest?.sport_name}
                                  />
                                </div>
                                <div className="font12">
                                  {t("registration_closes")} -{" "}
                                  {moment(
                                    homeData?.Contest?.reg_close_date_time
                                  ).format("MM/DD/YYYY HH:mm")}
                                </div>
                                <div className="p-2 pantagon-text">
                                  <p>
                                    {/* {homeData?.Contest?.description[
                                      i18n?.language
                                    ] ? (
                                      homeData?.Contest?.description[
                                        i18n?.language
                                      ] ? (
                                        (homeData?.Contest?.description[
                                          i18n?.language
                                        ]).substring(0, 100)
                                      ) : (
                                        (homeData?.Contest?.description[
                                          "en"
                                        ]).substring(0, 100)
                                      )
                                    ) : (
                                      <></>
                                    )} */}
                                    <RemoteLanguage field={homeData?.Contest?.description} />
                                  </p>
                                </div>
                              </div>
                              <div className="con-and-context text-orange">
                                {t("Contests")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="home-welcome">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-center font30 font-w800 latterspace5 my-5 my-sm-2">
                          {homeData?.welcome_title}
                        </div>
                      </div>
                    </div>
                    <div className="mb-5">
                      <div className="row justify-content-between">
                        <div className="col-lg-5 col-md-12">
                          <div className="white-bg radius5 my-4">
                            <div className="headigninfo radius5 pacifico">
                              <div className="row align-items-center">
                                <div className="col-md-10">
                                  {homeData?.media_credit_title}
                                </div>
                                <div className="col-md-2 text-center">
                                  <div className="bymedia">
                                    <img
                                      src="/images/icons/camera.svg"
                                      alt="camera"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="p-3">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: homeData?.media_credit_description,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-5 col-md-12">
                          <div className="white-bg radius5 my-4">
                            <div className="headigninfo radius5 pacifico">
                              <div className="row align-items-center">
                                <div className="col-md-9">
                                  {homeData?.contest_title}
                                </div>
                                <div className="col-md-3 text-center">
                                  {/* <img src="/images/icons/info-white-icon.svg" className="me-2" /> */}
                                  <div className="bymedia">
                                    <img
                                      src="/images/icons/tiket.svg"
                                      className="mtl"
                                      alt="tiket"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="p-3">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: homeData?.contest_description,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="orange-bg can-you-safely py-4">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-9">
                    <div className="fontborder-white text-uppercase my-3">
                      <em>{homeData?.section2_title}</em>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <a
                      href="#"
                      onClick={() => {
                        setVisibleModel("signup")
                      }}
                      className="btn btn-white view-more-btn"
                    >
                      {t("sign_up")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="secpad two-point-play-bg text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="fontborder mb-3">
                    {homeData?.section3_title}
                  </div>
                  <div className="font22 font-w500 mb-3">
                    {homeData?.section3_description ? (
                      homeData?.section3_description.substring(0, 100)
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="container">
                    <div className="row row-cols-2 row-cols-lg-5 g-4 g-lg-3 justify-content-center">
                      {homeData?.section3_image?.map(
                        (img: string, index: number) => {
                          return (
                            <div className="col">
                              <div className="text-center">
                                <img
                                  src={generateFileUrl(img)}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          )
                        }
                      )}
                    </div>
                  </div>

                  <div className="text-end mt-3 pacifico font40">
                    {homeData?.section3_bottom_text}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="secpad orange-bg unlock-vault-prizes">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-2">
                  <img
                    src="/images/unlock-vault-prizes-base.png"
                    alt="base"
                    className="img-fluid"
                  />
                </div>
                <div className="col-lg-10">
                  <div className="font64 font-w500 text-black mb-3">
                    {homeData?.contact_us}
                  </div>
                  <div>
                    <a
                      href="mailto:contact@safetysurvivor.com"
                      className="btn btn-white view-more-btn"
                    >
                      {t("Contact Us")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  )
}

export default Home
