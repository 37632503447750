import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import { useState, useEffect } from "react"
import ProductDetail from "../component/productDetail"
import MultiRangeSlider from "../component/multiRangeSlider/MultiRangeSlider"
import { useSelector } from "react-redux"
import { getProductsAsync } from "./../stores/cartSlice"

export const Store = () => {
  const { t } = useTranslation()
  const { profile } = useAppSelector((state) => state.app)
  const { allProducts } = useSelector((state: any) => state.cart)

  const [products, setProducts] = useState<any>([])
  const [productDetail, setProductDetail] = useState<any>({})
  const [searchText, setSearchText] = useState("")
  const [minPrice, setMinPrice] = useState(0)
  const [maxPrice, setMaxPrice] = useState(100)
  const [listDateWise, setListDateWise] = useState("Newest")
  const [selectedTag, setSelectedTag] = useState("")
  const dispatch = useAppDispatch()
  const [isClear, setIsClear] = useState(false)

  useEffect(() => {
    dispatch(getProductsAsync())
    setProducts(allProducts)
  }, [])

  useEffect(() => {
    setProducts(allProducts)
  }, [allProducts])

  const listData = products.filter((e: any) => {
    if (isClear) {
      setIsClear(false)
      return e?.title.toUpperCase().indexOf(e?.title) >= 0
    }
    var _selectedPriceListId = null
    if (
      e?.variants[0]?.price / 100 >= minPrice &&
      e?.variants[0]?.price / 100 <= maxPrice
    ) {
      _selectedPriceListId = e?.id
    }
    var _selectedtaglist = null
    if (selectedTag !== "") {
      for (let i = 0; i < e?.tags.length; i++) {
        if (e?.tags[i] == selectedTag) {
          _selectedtaglist = e?.tags[0]
          break
        }
      }
      return (
        e?.title.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 &&
        e?.tags[0]
          ?.toUpperCase()
          .indexOf(_selectedtaglist?.toString().toUpperCase()) >= 0 &&
        e?.id.indexOf(_selectedPriceListId) >= 0
      )
    } else {
      return (
        e?.title.toUpperCase().indexOf(searchText.toUpperCase()) >= 0 &&
        e?.id.indexOf(_selectedPriceListId) >= 0
      )
    }
  })

  // let sorted =
  //   listDateWise == "Oldest"
  //     ? listData.sort(function (a: any, b: any) {
  //         return new Date(b?.created_at) - new Date(a?.created_at)
  //       })
  //     : () => {
  //         return listData
  //       }

  // return (<>
  //   <div className="m-5">
  //     <div className="position-relative contests-reg font-w300 font45 pb-2 mb-3 text-center mt-3">
  //       {t("Store")}
  //     </div>
  //     <div className=" font-w300 font22 pt-4 mt-4 text-center">
  //       {t("Comming Soon")}
  //     </div>
  //   </div>
  // </>)

  return (
    <>
      <div className="contentpart">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="position-relative contests-reg font-w300 font50 pb-3 mb-3 text-center">
                {t("Our Official Store")}
              </div>
              {!profile?.user_id ? (
                <div className="text-center">
                  {t(
                    "For access to additional items and designs, please sign up or log in!"
                  )}
                </div>
              ) : (
                <div className="text-center">
                  {t(
                    "Welcome to our Official Store! To access items exclusively available to you, please go to ‘My Account’ -> ‘Achievements.’"
                  )}
                </div>
              )}

              <div className="filter-box mt-5 mb-4">
                <div className="filters">
                  <ul>
                    {/* <li>
                      <div className="dropdown">
                        {" "}
                        <a
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          data-bs-offset="10,20"
                          className="btn dropdown-toggle"
                        >
                          <img
                            src="/images/icons/shorting.svg"
                            alt="shorting"
                          />{" "}
                          {listDateWise}{" "}
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right">
                          <li>
                            {listDateWise == "Oldest" ? (
                              <button
                                className="dropdown-item"
                                onClick={() => {
                                  setListDateWise("Newest")
                                  // sortData()
                                }}
                              >
                                Newest
                              </button>
                            ) : (
                              <button
                                className="dropdown-item"
                                onClick={() => {
                                  setListDateWise("Oldest")
                                  // sortData()
                                }}
                              >
                                Oldest
                              </button>
                            )}
                          </li>
                        </ul>
                      </div>
                    </li> */}
                    <li className="filter">
                      <a
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-offset="10,20"
                        className="btn btn-orange dropdown-toggle"
                      >
                        <img src="/images/icons/filters.svg" alt="filters" />{" "}
                        {t("filters")}
                      </a>
                      <ul className="dropdown-menu dropdown-menu-right">
                        <li>
                          <div>
                            <button
                              className="dropdown-item text-end text-decoration-underline"
                              onClick={() => {
                                setSearchText("")
                                setSelectedTag("")
                                setMinPrice(0)
                                setMaxPrice(100)
                                setIsClear(true)
                              }}
                            >
                              {t("Clear Filters")}
                            </button>
                          </div>
                          <div className="mt-2 position-relative">
                            <label className="font-w600 d-block mb-2">
                              {t("Search")}
                            </label>
                            <input
                              type="text"
                              value={searchText}
                              className="form-control pe-5"
                              placeholder="Search"
                              onChange={(val: any) => {
                                setSearchText(val?.target?.value)
                              }}
                            />
                            <img
                              src="/images/icons/search.svg"
                              alt="search"
                              className="searchicon"
                            />
                          </div>
                          {/* <div className="mt-4">
                            <label className="font-w600 d-block mb-2">
                              Categories
                            </label>
                            <div className="card">
                              <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                  <a href="#">
                                    All Product<span>(25)</span>
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a href="#">
                                    Best Seller<span>(12)</span>
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a href="#">
                                    Featured<span>(03)</span>
                                  </a>
                                </li>
                                <li className="list-group-item">
                                  <a href="#">
                                    New Products<span>(10)</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div> */}
                          <div className="mt-4">
                            <label className="font-w600 d-block mb-2">
                              {t("price")}
                            </label>
                            <div className="card">
                              <ul className="list-group list-group-flush">
                                <li className="list-group-item pricerange">
                                  <MultiRangeSlider
                                    min={0}
                                    max={100}
                                    onChange={({ min, max }) => {
                                      setMaxPrice(max)
                                      setMinPrice(min)
                                    }}
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="mt-5">
                            <label className="font-w600 d-block mb-2">
                              {t("Tags")}
                            </label>
                            <div className="accordion" id="FillterAccordion">
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingWomen"
                                >
                                  <button
                                    className={
                                      selectedTag == "Men's Clothing"
                                        ? "accordion-button collapsed orange-text font-w500"
                                        : "accordion-button collapsed"
                                    }
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseWomen"
                                    aria-expanded="false"
                                    aria-controls="collapseWomen"
                                    onClick={() => {
                                      setSelectedTag("Men's Clothing")
                                    }}
                                  >
                                    {t("Men's Clothing")}{" "}
                                  </button>
                                </h2>
                              </div>
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingWomen"
                                >
                                  <button
                                    className={
                                      selectedTag == "Women's Clothing"
                                        ? "accordion-button collapsed orange-text font-w500"
                                        : "accordion-button collapsed"
                                    }
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseWomen"
                                    aria-expanded="false"
                                    aria-controls="collapseWomen"
                                    onClick={() => {
                                      setSelectedTag("Women's Clothing")
                                    }}
                                  >
                                    {t("Women's Clothing")}{" "}
                                  </button>
                                </h2>
                                {/* <div
                                  id="collapseWomen"
                                  className="accordion-collapse collapse"
                                  aria-labelledby="headingWomen"
                                  data-bs-parent="#FillterAccordion"
                                >
                                  <div className="accordion-body">
                                    {" "}
                                    Women Content 01
                                    <br />
                                    Women Content 02
                                    <br />
                                    Women Content 03
                                    <br />
                                  </div>
                                </div> */}
                              </div>
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingFootwear"
                                >
                                  <button
                                    className={
                                      selectedTag == "Footwear"
                                        ? "accordion-button collapsed orange-text font-w500"
                                        : "accordion-button collapsed"
                                    }
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFootwear"
                                    aria-expanded="false"
                                    aria-controls="collapseFootwear"
                                    onClick={() => {
                                      setSelectedTag("Footwear")
                                    }}
                                  >
                                    {" "}
                                    {t("Footwear")}{" "}
                                  </button>
                                </h2>
                              </div>
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingFootwear"
                                >
                                  <button
                                    className={
                                      selectedTag == "Hats"
                                        ? "accordion-button collapsed orange-text font-w500"
                                        : "accordion-button collapsed"
                                    }
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFootwear"
                                    aria-expanded="false"
                                    aria-controls="collapseFootwear"
                                    onClick={() => {
                                      setSelectedTag("Hats")
                                    }}
                                  >
                                    {" "}
                                    {t("Hats")}{" "}
                                  </button>
                                </h2>
                              </div>
                              <div className="accordion-item">
                                <h2
                                  className="accordion-header"
                                  id="headingAccessories"
                                >
                                  <button
                                    className={
                                      selectedTag == "Accessories"
                                        ? "accordion-button collapsed orange-text font-w500"
                                        : "accordion-button collapsed"
                                    }
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseAccessories"
                                    aria-expanded="false"
                                    aria-controls="collapseAccessories"
                                    onClick={() => {
                                      setSelectedTag("Accessories")
                                    }}
                                  >
                                    {" "}
                                    {("Accessories")}{" "}
                                  </button>
                                </h2>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div className="flclear"></div>
                </div>
              </div>

              <div className="product-listing">
                <div className="row">
                  {listData.map((product: any, index: number) => {
                    return (
                      <div
                        key={`product_${index}`}
                        className="col-lg-4 col-md-6"
                      >
                        <div className="storeproduct">
                          <div className="store-pro-bg">
                            {/* <div className="offer">
                              <span>30%</span>
                              <br /> OFF
                            </div> */}
                            <img
                              width={"300px"}
                              src={product.images[0]?.src || "/images/logo.png"}
                              className="d-block m-auto"
                              alt="tshirt"
                            />
                            <div
                              id={`storepro${index}`}
                              className="carousel slide"
                              data-bs-ride="true"
                            >
                              <div className="carousel-inner">
                                <div
                                  key={`proImg_${index}`}
                                  className={
                                    index == 1
                                      ? "carousel-item active"
                                      : "carousel-item"
                                  }
                                ></div>
                              </div>
                            </div>
                            <div className="zoomicon">
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#Product-Details-Modal"
                                onClick={() => {
                                  setProductDetail(product)
                                }}
                              >
                                <img src="/images/icons/zoom.svg" alt="zoom" />
                              </a>
                            </div>
                          </div>
                          <div className="store-pro-info">
                            <h2>{product.title}</h2>

                            {product.variants.map(
                              (variant: any, index: number) => {
                                return (
                                  variant.is_default && (
                                    <div className="price">
                                      {variant?.price / 100} CAD
                                    </div>
                                  )
                                )
                              }
                            )}

                            <div className="text-center">
                              <a
                                href="#"
                                className="btn btn-white btn-shadow"
                                data-bs-toggle="modal"
                                data-bs-target="#Product-Details-Modal"
                                onClick={() => {
                                  setProductDetail(product)
                                }}
                              >
                                <img
                                  src="/images/icons/plus-green.svg"
                                  className="me-2"
                                  alt=""
                                />
                                {t("Add to cart")}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  {listData.length === 0 && (
                    <div className=" font-w300 font22 pt-4 m-4 text-center">
                      {t("No Item Found")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProductDetail
          productDetail={productDetail}
          setProductDetail={setProductDetail}
        />
      </div>
    </>
  )
}
