import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getVideoList, VideoAccessBuy, getVideoById } from "../api/appApi"

export interface AppStateInterface {
  status: "idle" | "loading" | "failed" | "created" | "updated" | "deleted"
  videoList: any
  VideoListByLangCode: any
  videoDetail: any
  videoAccessBuy: any
  popularVideo: any
  videoListCount: number
  showPerchaseModal: any
}

const initialState: AppStateInterface = {
  status: "idle",
  videoList: [],
  VideoListByLangCode: [],
  videoDetail: {},
  videoAccessBuy: {},
  popularVideo: {},
  videoListCount: 0,
  showPerchaseModal: { video_id: "", title: "", credit: "" },
}

export const getVideoListAsync = createAsyncThunk(
  "VideoList",
  async (params: any) => {
    const response = await getVideoList(
      params.limit,
      params.page,
      params.language_id,
      params.filterValue,
      params.sport_id
    )
    return { params: params, data: response.data }
  }
)

export const videoAccessBuyAsync = createAsyncThunk(
  "videoAccessBuy",
  async (data: any) => {
    const response: any = await VideoAccessBuy(data)
    return response.data
  }
)

export const getVideoByIdAsync = createAsyncThunk(
  "VideoById",
  async (id: string) => {
    const response = await getVideoById(id)
    return response.data
  }
)

export const videoSlice = createSlice({
  name: "video",
  initialState,

  reducers: {
    resetVideoAccessBuy: (state) => {
      state.videoAccessBuy = {}
      state.showPerchaseModal = { video_id: "", title: "", credit: "" }
    },
    resetVideoList: (state) => {
      state.videoList = []
    },
    setShowPerchaseModal: (state, action: PayloadAction<any>) => {
      state.showPerchaseModal = action.payload
    },
  },

  extraReducers: (builder) => {
    // get Video List
    builder.addCase(getVideoListAsync.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getVideoListAsync.fulfilled, (state, action) => {
      state.status = "idle"
      state.videoListCount = action.payload.data.count

      if (action.payload.params.page == 1) {
        state.videoList = action.payload.data.data
      } else {
        state.videoList = [...state.videoList, ...action.payload.data.data]
      }
    })
    builder.addCase(getVideoListAsync.rejected, (state) => {
      state.status = "failed"
    })

    // Video Access Buy
    builder.addCase(videoAccessBuyAsync.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(videoAccessBuyAsync.fulfilled, (state, action) => {
      state.status = "idle"
      state.videoAccessBuy = action.payload
    })
    builder.addCase(videoAccessBuyAsync.rejected, (state) => {
      state.status = "failed"
    })

    // get Video By ID
    builder.addCase(getVideoByIdAsync.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(getVideoByIdAsync.fulfilled, (state, action) => {
      state.status = "idle"
      state.videoDetail = action.payload.data
    })
    builder.addCase(getVideoByIdAsync.rejected, (state) => {
      state.status = "failed"
    })
  },
})

export const { resetVideoAccessBuy, resetVideoList, setShowPerchaseModal } =
  videoSlice.actions
export const videoState = (state: any) => state.video
export default videoSlice.reducer
function videoAccessBuy(data: any) {
  throw new Error("Function not implemented.")
}
