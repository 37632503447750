import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../stores/hooks"
import HomePageTab from "../component/hometabs"
import { contestState, getContestAsync, resetContestById } from "../stores/contestSlice"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { generateFileUrl } from "../utils/AzureStorageBlob"
import RemoteLanguage from "../component/remoteLang"
import Rating from "../component/rating"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { localNumber, numberFormat } from "../utils/commonFun"
import { Tooltip } from 'react-tooltip';
import { appState } from "../stores/appSlice"

export const Dashboard = () => {

    const dispatchApp = useAppDispatch()
    const { contest, status } = useAppSelector(contestState);
    const { appLoader } = useAppSelector(appState);
    const [limit, setLimit] = useState(3);
    const [page, setPage] = useState(1);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    useEffect(() => {
        setPage(1)
    }, [params?.status])

    useEffect(() => {
        let data = {
            limit: limit,
            page: page,
            status: params?.status ? "In progress" : "Open"
        }
        dispatchApp(getContestAsync(data));
    }, [limit, page, params?.status])

    const gotoContestDetail = (c_id: string, user_credit: number) => {
        if (location.pathname == '/contest/active' && params.status == "active") {
            dispatchApp(resetContestById())
            navigate(`/contest-detail/${c_id}`)
        } else if (user_credit > 0 && location.pathname != '/contest/active') {
            dispatchApp(resetContestById())
            navigate(`/contest-detail/${c_id}`)
        } else {
            navigate(`/buy-media-credit`)
        }
    }

    return (
        <section className="contentpart">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className={ contest?.length > 0 ? "contests-sec text-center mb-4" : "contests-sec text-center"}>
                            {params?.status == "active" ?
                                <div className="position-relative contests-reg pb-2 mb-3">
                                    <span className="font-w300 font45"> {t("my_active_contests")}</span>
                                    <a href="#!" data-tooltip-id="my-tooltip">
                                        <img src="/images/icons/info.svg" alt="info" className="ms-2" />
                                    </a>
                                    <Tooltip
                                        id="my-tooltip"
                                        content={t("All completed contests can be found in 'My Account.' ")}
                                        closeOnScroll
                                        openOnClick
                                    />
                                </div>
                                : contest?.length > 0 &&
                                <div className="position-relative contests-reg pb-2 mb-3">
                                    <span className="font-w300 font45"> {t('contests_open_for_registration')} </span>
                                </div>
                            }

                            {!appLoader && contest?.length == 0 && params?.status == "active" && <div className=" font-w300 font22 pt-4 m-4">{t('No Contests Active')}</div>}


                            {contest?.length > 0 && params?.status != "active" && <div className="mb-4">{t('one_entry_granted_with_the_purchase_of_each_media_credit')}
                                <a href="">
                                <a href="#!" data-tooltip-id="my-tooltip">
                                    <img src="/images/icons/info.svg" alt="info" className="ms-1" />
                                </a>
                                <Tooltip
                                    id="my-tooltip"
                                    content={t("No purchase necessary. Please refer to official contest rules")}
                                    closeOnScroll
                                    openOnClick
                                />
                                </a>
                            </div>}


                            <div className="row">

                                {contest?.map((item: any, index: number) => {
                                    return (
                                        <div key={`contest_ ${index}`} className="col-lg-4 col-md-12">
                                            <div
                                                onClick={() => { gotoContestDetail(item.contest_id, Number(item?.user_contest_credit)) }
                                                } className="pointer sports-list">
                                                <div className="home-sports-box-bg">
                                                    <div className="sportimg">
                                                        <img src={generateFileUrl(item?.banner)} alt="Football" />
                                                    </div>
                                                    <h2>
                                                        <RemoteLanguage field={item?.title} />
                                                        {item?.rules_file && <Link onClick={(e) => { e.stopPropagation() }} to={generateFileUrl(item?.rules_file)} target="_blank"><img src="/images/icons/notepad.svg" alt="notepad" className="ms-1" /></Link>}
                                                    </h2>
                                                    <span className="prize-per-winning-entry">{t('prize_per_winning_entry')} <span>{numberFormat(item?.price_pool)}</span></span>
                                                    <div className="font-w500 text-black"><RemoteLanguage field={item?.Sport?.sport_name} /></div>
                                                    {item.status === "In progress" && params.status == "active" ?
                                                        <div className="font12">{t('In progress')}</div>
                                                        :
                                                        <div className="font12">{t('registration_closes')} - {moment(item?.reg_close_date_time).format('MM/DD/YYYY HH:mm')}</div>
                                                    }
                                                </div>
                                                <Rating complexity={item?.complexity} />
                                                <div className="hrline"></div>

                                                {item?.status === "Open" && item?.user_contest_credit > 0 ?
                                                    <div className="font16 font-w500 line18 mb-3 fx-center">
                                                        <img src="/images/icons/tiket.svg" alt="tiket" className="me-3" />
                                                        <span className="font20"> {localNumber(item?.user_contest_credit)}</span>
                                                        {item?.user_contest_credit > 0 && <img src="/images/icons/green-check.svg" alt="tiket" className="ms-2" />}
                                                    </div>
                                                    :
                                                    item?.status === "In progress" ?
                                                        <div className="font16 font-w500 line18 mb-3 fx-center">
                                                            <img src="/images/icons/tiket.svg" alt="tiket" className="me-3" />
                                                            <span>
                                                                <span className="font20"> {localNumber(item?.user_contest_credit)}</span>
                                                                /
                                                                <span className="font12">{localNumber(item?.user_contest_total_credit)}</span>
                                                            </span>
                                                            {item?.user_contest_credit > 0 && <img src="/images/icons/green-check.svg" alt="tiket" className="ms-2" />}
                                                        </div>
                                                        :
                                                        <div className="font16 font-w500 line18 mb-3 fx-center"> <br /> </div>
                                                }

                                                <div><RemoteLanguage field={item?.description} /></div>
                                            </div>
                                        </div>
                                    );
                                })}

                            </div>
                            { contest?.length > 0 && contest?.length == (page * limit) && status != "loading" ?
                                <div className="mt-3"><a onClick={() => { setPage(page + 1) }} className="btn btn-orange box-shadow view-more-btn">{status == "loading" && !appLoader ? t("loading") : t("view-more")} </a></div>
                                : null}
                        </div>
                        
                        {params?.status != "active" && <HomePageTab />}

                    </div>
                </div>
            </div>
        </section>
    )
}