import { t } from "i18next"
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"

const ComingSoon = () => {

    const location = useLocation();

    return (
        <div className="contentpart">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="position-relative contests-reg font-w300 font50 pb-3 mb-3 text-center">{t(location.state)}</div>
                        <div className="product-listing">
                            <div className="text-center mt-5 mb-5 font-w300 font22">{t("Comming Soon")}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ComingSoon